export const LandingRoute = () => '/';
export const LoadingRoute = () => '/loading';
export const DashboardRoute = () => '/dashboard';
export const PTRoute = () => '/pientere-tuinen';
export const PTRegisterRoute = () => '/pientere-tuinen/register';
export const PTTreeMonitorRoute = () => '/pientere-tuinen/bomen-in-de-buurt';
export const PTOpenApiSpecRoute = () => '/api-subscriptions/open-api/mijn-pientere-tuin';
export const ApiSubscriptionsRoute = () => '/api-subscriptions';

export const SRRouteDashboard = () => '/registered-sensor';
export const SRRoute = (orgId?: string) => (orgId ? `/registered-sensor/${orgId}` : '/registered-sensor/:orgId');
export const SRAssetCreateRoute = (orgId? :string) => (orgId ? `/registered-sensor/${orgId}/asset/` : '/registered-sensor/:orgId/asset');
export const SRAssetImportRoute = (orgId? :string) => (orgId ? `/registered-sensor/${orgId}/asset-import/` : '/registered-sensor/:orgId/asset-import');
export const SRAssetRoute = (orgId?: string, assetId?: string) => ((assetId && orgId) ? `/registered-sensor/${orgId}/asset/${assetId}` : '/registered-sensor/:orgId/asset/:assetId');

// admin
export const ImportHubspotContactListRoute = () => '/admin/hubspot-import';
export const CreateSensorRegistrationTokensRoute = () => '/admin/create-sensor-registration-tokens';
export const SendVerificationEmailRoute = () => '/admin/send-verification-email';
export const OrganizationManagementRoute = () => '/admin/organization-management';
export const OrganizationUsersRoute = (orgId?: string) => (orgId ? `/admin/organization-management/${orgId}/users` : '/admin/organization-management/:orgId/users');
export const AssetManagementRoute = () => '/admin/asset-management';

export const UserManagementRoute = () => '/admin/user-management';
export const UserRoute = (userId?: string) => (userId ? `/admin/user-management/user/${userId}` : '/admin/user-management/user/:userId');
export const UserRolesRoute = (userId?: string) => (userId ? `/admin/user-management/user/${userId}/roles` : '/admin/user-management/user/:userId/roles');
export const UserAssetsRoute = (userId?: string) => (userId ? `/admin/user-management/user/${userId}/assets` : '/admin/user-management/user/:userId/assets');
