import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A date time with timezone formatted according to RFC3339 (without millis, we're not timing F1 here) */
  DateTime: any;
  /** Base64 string */
  Base64: any;
  /** A date time with timezone formatted according to RFC3339 (without millis, we're not timing F1 here) */
  Instant: any;
  /** Valid JSON */
  Json: any;
};

export type Query = {
  __typename?: 'Query';
  pientereTuinenAssetsForUser: AssetsResult;
  registeredSensorAssets: AssetsResult;
  searchRegisteredSensorAssets: AssetsResult;
  registeredSensorAsset: AssetResult;
  scheme?: Maybe<SchemeResult>;
  adminAssetsForUser?: Maybe<AssetsResult>;
  adminSearchAssets: AssetsResult;
  cube: Array<Result>;
  platformAlerts: Array<PlatformAlert>;
  myOrganizationRoles: Array<OrganizationUser>;
  myUserRoles: Array<RoleUser>;
  organizations: Array<Organization>;
  organization: Organization;
  userSearch: Array<UserSummary>;
  user: User;
  mySensorRegistrationToken?: Maybe<SensorRegistrationToken>;
  sensoterraSensor?: Maybe<SensoterraSensor>;
  hubspot: HubspotQuery;
  apiCatalog: ApiCatalog;
  trees: Trees;
  dataMarket: DataMarket;
};


export type QueryRegisteredSensorAssetsArgs = {
  filterQuery?: InputMaybe<Scalars['String']>;
};


export type QuerySearchRegisteredSensorAssetsArgs = {
  searchQuery?: InputMaybe<Scalars['String']>;
};


export type QueryRegisteredSensorAssetArgs = {
  id: Scalars['ID'];
};


export type QueryAdminAssetsForUserArgs = {
  userId: Scalars['ID'];
};


export type QueryAdminSearchAssetsArgs = {
  searchQuery?: InputMaybe<Scalars['String']>;
};


export type QueryCubeArgs = {
  where?: InputMaybe<RootWhereInput>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  timezone?: InputMaybe<Scalars['String']>;
  renewQuery?: InputMaybe<Scalars['Boolean']>;
  orderBy?: InputMaybe<RootOrderByInput>;
};


export type QueryOrganizationArgs = {
  id: Scalars['ID'];
};


export type QueryUserSearchArgs = {
  query: UserSearchQuery;
};


export type QueryUserArgs = {
  id: Scalars['ID'];
};


export type QuerySensoterraSensorArgs = {
  id: Scalars['ID'];
};

export type Property = {
  __typename?: 'Property';
  id: Scalars['ID'];
  key?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type PropertyInput = {
  id?: InputMaybe<Scalars['ID']>;
  key?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

export type Asset = {
  __typename?: 'Asset';
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  properties?: Maybe<Array<Property>>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type PaginationLink = {
  __typename?: 'PaginationLink';
  url: Scalars['String'];
  active: Scalars['Boolean'];
  label?: Maybe<Scalars['String']>;
};

export type PaginationMeta = {
  __typename?: 'PaginationMeta';
  currentPage: Scalars['Int'];
  from?: Maybe<Scalars['Int']>;
  lastPage?: Maybe<Scalars['Int']>;
  perPage: Scalars['Int'];
  to?: Maybe<Scalars['Int']>;
  total: Scalars['Int'];
  links?: Maybe<Array<PaginationLink>>;
};

export type AssetsResult = {
  __typename?: 'AssetsResult';
  data?: Maybe<Array<Asset>>;
  meta?: Maybe<PaginationMeta>;
};

export type AssetResult = {
  __typename?: 'AssetResult';
  data: Asset;
};

export type PropertyResult = {
  __typename?: 'PropertyResult';
  data: Property;
};

export type PropertyType = {
  __typename?: 'PropertyType';
  name?: Maybe<Scalars['String']>;
  validation?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
};

export type Scheme = {
  __typename?: 'Scheme';
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  propertyTypes?: Maybe<Array<PropertyType>>;
};

export type SchemeResult = {
  __typename?: 'SchemeResult';
  data?: Maybe<Scheme>;
};

export type Group = {
  __typename?: 'Group';
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
};

export type GroupResult = {
  __typename?: 'GroupResult';
  data: Group;
};

export type Mutation = {
  __typename?: 'Mutation';
  createRegisteredSensorAsset: AssetResult;
  bulkCreateRegisteredSensorAssets: AssetsResult;
  updateRegisteredSensorAsset: AssetResult;
  updateRegisteredSensorProperty: PropertyResult;
  deleteRegisteredSensorProperty: PropertyResult;
  deleteRegisteredSensorAsset: AssetResult;
  createPientereTuinenAsset: AssetResult;
  updatePientereTuinenAsset: AssetResult;
  createPientereTuinenProperty: PropertyResult;
  updatePientereTuinenProperty: PropertyResult;
  adminDeleteAsset: AssetResult;
  adminCreateGroup: GroupResult;
  organizations: OrganizationsMutation;
  users: UsersMutation;
  hubspot: HubspotMutation;
  sensors: SensorsMutation;
  apiCatalog: ApiCatalogMutation;
  trees: TreesMutation;
  dataMarket: DataMarketMutation;
};


export type MutationCreateRegisteredSensorAssetArgs = {
  input: CreateAssetCommand;
};


export type MutationBulkCreateRegisteredSensorAssetsArgs = {
  input: Array<InputMaybe<CreateAssetCommand>>;
};


export type MutationUpdateRegisteredSensorAssetArgs = {
  input: UpdateAssetCommand;
};


export type MutationUpdateRegisteredSensorPropertyArgs = {
  input: UpdatePropertyCommand;
};


export type MutationDeleteRegisteredSensorPropertyArgs = {
  input: DeletePropertyCommand;
};


export type MutationDeleteRegisteredSensorAssetArgs = {
  assetId: Scalars['ID'];
};


export type MutationCreatePientereTuinenAssetArgs = {
  input: CreateAssetCommand;
};


export type MutationUpdatePientereTuinenAssetArgs = {
  input: UpdateAssetCommand;
};


export type MutationCreatePientereTuinenPropertyArgs = {
  input: CreatePropertyCommand;
};


export type MutationUpdatePientereTuinenPropertyArgs = {
  input: UpdatePropertyCommand;
};


export type MutationAdminDeleteAssetArgs = {
  assetId: Scalars['ID'];
};


export type MutationAdminCreateGroupArgs = {
  input: CreateGroupCommand;
};

export type CreateAssetCommand = {
  id?: InputMaybe<Scalars['ID']>;
  name: Scalars['String'];
  properties?: InputMaybe<Array<PropertyInput>>;
};

export type UpdateAssetCommand = {
  id?: InputMaybe<Scalars['ID']>;
  name: Scalars['String'];
  properties?: InputMaybe<Array<PropertyInput>>;
};

export type UpdatePropertyCommand = {
  assetId: Scalars['ID'];
  propertyId: Scalars['ID'];
  value: Scalars['String'];
};

export type CreatePropertyCommand = {
  assetId: Scalars['ID'];
  key: Scalars['String'];
  value: Scalars['String'];
  type: Scalars['String'];
};

export type DeletePropertyCommand = {
  assetId: Scalars['ID'];
  propertyId: Scalars['ID'];
};

export type CreateGroupCommand = {
  id?: InputMaybe<Scalars['ID']>;
  name: Scalars['String'];
};

export type FloatFilter = {
  equals?: InputMaybe<Scalars['Float']>;
  notEquals?: InputMaybe<Scalars['Float']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
  set?: InputMaybe<Scalars['Boolean']>;
  gt?: InputMaybe<Scalars['Float']>;
  lt?: InputMaybe<Scalars['Float']>;
  gte?: InputMaybe<Scalars['Float']>;
  lte?: InputMaybe<Scalars['Float']>;
};

export type StringFilter = {
  equals?: InputMaybe<Scalars['String']>;
  notEquals?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contains?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  notContains?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  startsWith?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  notStartsWith?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  endsWith?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  notEndsWith?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  set?: InputMaybe<Scalars['Boolean']>;
};

export type DateTimeFilter = {
  equals?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  notEquals?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  inDateRange?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  notInDateRange?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  beforeDate?: InputMaybe<Scalars['String']>;
  afterDate?: InputMaybe<Scalars['String']>;
  set?: InputMaybe<Scalars['Boolean']>;
};

export enum OrderBy {
  Asc = 'asc',
  Desc = 'desc'
}

export type TimeDimension = {
  __typename?: 'TimeDimension';
  value: Scalars['DateTime'];
  second: Scalars['DateTime'];
  minute: Scalars['DateTime'];
  hour: Scalars['DateTime'];
  day: Scalars['DateTime'];
  week: Scalars['DateTime'];
  month: Scalars['DateTime'];
  quarter: Scalars['DateTime'];
  year: Scalars['DateTime'];
};

export type ApiCatalogMeteringMembers = {
  __typename?: 'ApiCatalogMeteringMembers';
  sum_calls?: Maybe<Scalars['Float']>;
  sum_bytes?: Maybe<Scalars['Float']>;
  api?: Maybe<Scalars['String']>;
  key_id?: Maybe<Scalars['String']>;
  day?: Maybe<TimeDimension>;
  user_id?: Maybe<Scalars['String']>;
};

export type ApiCatalogMeteringWhereInput = {
  AND?: InputMaybe<Array<ApiCatalogMeteringWhereInput>>;
  OR?: InputMaybe<Array<ApiCatalogMeteringWhereInput>>;
  sum_calls?: InputMaybe<FloatFilter>;
  sum_bytes?: InputMaybe<FloatFilter>;
  api?: InputMaybe<StringFilter>;
  key_id?: InputMaybe<StringFilter>;
  day?: InputMaybe<DateTimeFilter>;
  user_id?: InputMaybe<StringFilter>;
};

export type ApiCatalogMeteringOrderByInput = {
  sum_calls?: InputMaybe<OrderBy>;
  sum_bytes?: InputMaybe<OrderBy>;
  api?: InputMaybe<OrderBy>;
  key_id?: InputMaybe<OrderBy>;
  day?: InputMaybe<OrderBy>;
  user_id?: InputMaybe<OrderBy>;
};

export type PientereTuinenMeasurementsMembers = {
  __typename?: 'PientereTuinenMeasurementsMembers';
  count?: Maybe<Scalars['Float']>;
  avg_temperature_celsius?: Maybe<Scalars['Float']>;
  min_temperature_celsius?: Maybe<Scalars['Float']>;
  max_temperature_celsius?: Maybe<Scalars['Float']>;
  p95_temperature_celsius?: Maybe<Scalars['Float']>;
  p05_temperature_celsius?: Maybe<Scalars['Float']>;
  avg_moisture_percentage?: Maybe<Scalars['Float']>;
  min_moisture_percentage?: Maybe<Scalars['Float']>;
  max_moisture_percentage?: Maybe<Scalars['Float']>;
  p95_moisture_percentage?: Maybe<Scalars['Float']>;
  p05_moisture_percentage?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['String']>;
  sensor_id?: Maybe<Scalars['String']>;
  moisture_percentage?: Maybe<Scalars['Float']>;
  temperature_celsius?: Maybe<Scalars['Float']>;
  garden_hardening_percentage?: Maybe<Scalars['Float']>;
  garden_orientation?: Maybe<Scalars['String']>;
  garden_size?: Maybe<Scalars['String']>;
  soil_type?: Maybe<Scalars['String']>;
  measured_at?: Maybe<TimeDimension>;
};

export type PientereTuinenMeasurementsWhereInput = {
  AND?: InputMaybe<Array<PientereTuinenMeasurementsWhereInput>>;
  OR?: InputMaybe<Array<PientereTuinenMeasurementsWhereInput>>;
  count?: InputMaybe<FloatFilter>;
  avg_temperature_celsius?: InputMaybe<FloatFilter>;
  min_temperature_celsius?: InputMaybe<FloatFilter>;
  max_temperature_celsius?: InputMaybe<FloatFilter>;
  p95_temperature_celsius?: InputMaybe<FloatFilter>;
  p05_temperature_celsius?: InputMaybe<FloatFilter>;
  avg_moisture_percentage?: InputMaybe<FloatFilter>;
  min_moisture_percentage?: InputMaybe<FloatFilter>;
  max_moisture_percentage?: InputMaybe<FloatFilter>;
  p95_moisture_percentage?: InputMaybe<FloatFilter>;
  p05_moisture_percentage?: InputMaybe<FloatFilter>;
  user_id?: InputMaybe<StringFilter>;
  sensor_id?: InputMaybe<StringFilter>;
  moisture_percentage?: InputMaybe<FloatFilter>;
  temperature_celsius?: InputMaybe<FloatFilter>;
  garden_hardening_percentage?: InputMaybe<FloatFilter>;
  garden_orientation?: InputMaybe<StringFilter>;
  garden_size?: InputMaybe<StringFilter>;
  soil_type?: InputMaybe<StringFilter>;
  measured_at?: InputMaybe<DateTimeFilter>;
};

export type PientereTuinenMeasurementsOrderByInput = {
  count?: InputMaybe<OrderBy>;
  avg_temperature_celsius?: InputMaybe<OrderBy>;
  min_temperature_celsius?: InputMaybe<OrderBy>;
  max_temperature_celsius?: InputMaybe<OrderBy>;
  p95_temperature_celsius?: InputMaybe<OrderBy>;
  p05_temperature_celsius?: InputMaybe<OrderBy>;
  avg_moisture_percentage?: InputMaybe<OrderBy>;
  min_moisture_percentage?: InputMaybe<OrderBy>;
  max_moisture_percentage?: InputMaybe<OrderBy>;
  p95_moisture_percentage?: InputMaybe<OrderBy>;
  p05_moisture_percentage?: InputMaybe<OrderBy>;
  user_id?: InputMaybe<OrderBy>;
  sensor_id?: InputMaybe<OrderBy>;
  moisture_percentage?: InputMaybe<OrderBy>;
  temperature_celsius?: InputMaybe<OrderBy>;
  garden_hardening_percentage?: InputMaybe<OrderBy>;
  garden_orientation?: InputMaybe<OrderBy>;
  garden_size?: InputMaybe<OrderBy>;
  soil_type?: InputMaybe<OrderBy>;
  measured_at?: InputMaybe<OrderBy>;
};

export type RootWhereInput = {
  AND?: InputMaybe<Array<RootWhereInput>>;
  OR?: InputMaybe<Array<RootWhereInput>>;
  api_catalog_metering?: InputMaybe<ApiCatalogMeteringWhereInput>;
  pientere_tuinen_measurements?: InputMaybe<PientereTuinenMeasurementsWhereInput>;
};

export type RootOrderByInput = {
  api_catalog_metering?: InputMaybe<ApiCatalogMeteringOrderByInput>;
  pientere_tuinen_measurements?: InputMaybe<PientereTuinenMeasurementsOrderByInput>;
};

export type Result = {
  __typename?: 'Result';
  api_catalog_metering: ApiCatalogMeteringMembers;
  pientere_tuinen_measurements: PientereTuinenMeasurementsMembers;
};


export type ResultApi_Catalog_MeteringArgs = {
  where?: InputMaybe<ApiCatalogMeteringWhereInput>;
  orderBy?: InputMaybe<ApiCatalogMeteringOrderByInput>;
};


export type ResultPientere_Tuinen_MeasurementsArgs = {
  where?: InputMaybe<PientereTuinenMeasurementsWhereInput>;
  orderBy?: InputMaybe<PientereTuinenMeasurementsOrderByInput>;
};

export type Api = {
  __typename?: 'Api';
  name: ApiName;
  plans: Array<ApiPlan>;
};

export type ApiCatalog = {
  __typename?: 'ApiCatalog';
  apis: Array<Api>;
  /** Subscriptions of the current user */
  userSubscriptions: Array<ApiSubscriptionUser>;
};

export type ApiCatalogMutation = {
  __typename?: 'ApiCatalogMutation';
  createUserSubscription: ApiSubscriptionUser;
  deleteUserSubscription: Scalars['ID'];
  createUserApiKey: Array<ApiKey>;
  deleteUserApiKey: Scalars['ID'];
};


export type ApiCatalogMutationCreateUserSubscriptionArgs = {
  command: CreateUserApiSubscriptionCommand;
};


export type ApiCatalogMutationDeleteUserSubscriptionArgs = {
  id: Scalars['ID'];
};


export type ApiCatalogMutationCreateUserApiKeyArgs = {
  subscriptionId: Scalars['ID'];
};


export type ApiCatalogMutationDeleteUserApiKeyArgs = {
  id: Scalars['ID'];
};

export type ApiKey = {
  __typename?: 'ApiKey';
  id: Scalars['ID'];
  key: Scalars['String'];
  createdAt: Scalars['Instant'];
  updatedAt: Scalars['Instant'];
};

export enum ApiName {
  MijnPientereTuin = 'MIJN_PIENTERE_TUIN'
}

export type ApiPlan = {
  __typename?: 'ApiPlan';
  slug: Scalars['String'];
};

export type ApiSubscriptionUser = {
  __typename?: 'ApiSubscriptionUser';
  id: Scalars['ID'];
  createdAt: Scalars['Instant'];
  updatedAt: Scalars['Instant'];
  api: Api;
  plan: ApiPlan;
  keys: Array<ApiKey>;
};

export type BulkOrganizationRolesCommand = {
  userIds: Array<Scalars['ID']>;
  orgId: Scalars['ID'];
  roles: Array<RoleOrganization>;
};

export type BulkUserRolesCommand = {
  userIds: Array<Scalars['ID']>;
  roles: Array<RoleUser>;
};

export type CreateDataProductPlanSubscriptionApiKey = {
  id?: InputMaybe<Scalars['ID']>;
  subscriptionId: Scalars['ID'];
};

export type CreateOrganizationCommand = {
  id?: InputMaybe<Scalars['ID']>;
  /** Slug of the organization, only a-z, 0-9, `-` */
  orgId: Scalars['ID'];
  name: Scalars['String'];
};

export type CreateProductCommand = {
  id?: InputMaybe<Scalars['ID']>;
  dataType: DataProductDataType;
  openDataProductSpecification: Scalars['Json'];
  active: Scalars['Boolean'];
};

export type CreateSensorRegistrationTokensCommand = {
  listId: Scalars['ID'];
  /** The orgId of the organization the registered sensors should belong to */
  orgId: Scalars['ID'];
};

export type CreateSensorRegistrationTokensResult = {
  __typename?: 'CreateSensorRegistrationTokensResult';
  createdTokensForUsers: Array<User>;
  errorMessages: Array<Scalars['String']>;
};

export type CreateTreeCommand = {
  inputSpecies: InputSpecies;
  heightClass: HeightInMeterClass;
  crownDiameterClass: HeightInMeterClass;
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
};

export type CreateUserApiSubscriptionCommand = {
  id?: InputMaybe<Scalars['ID']>;
  api: ApiName;
  /** Slug of the ApiPlan */
  plan: Scalars['String'];
};

export type CreateUserCommand = {
  id?: InputMaybe<Scalars['ID']>;
  firstname: Scalars['String'];
  lastname: Scalars['String'];
  email: Scalars['String'];
};

export type DataMarket = {
  __typename?: 'DataMarket';
  adminProducts: Array<DataProduct>;
  adminProduct: DataProduct;
  products: Array<DataProduct>;
  subscriptions?: Maybe<Array<DataProductPlanSubscription>>;
};


export type DataMarketAdminProductArgs = {
  id: Scalars['ID'];
};

export type DataMarketMutation = {
  __typename?: 'DataMarketMutation';
  adminCreateProduct: DataProduct;
  adminUpdateProduct: DataProduct;
  adminDeleteProduct: Scalars['ID'];
  adminSetHttpSource: DataProductHttpSource;
  adminDeleteHttpSource: Scalars['ID'];
  deleteSubscription: Scalars['ID'];
  createApiKey: DataProductPlanSubscription;
  deleteApiKey: DataProductPlanSubscription;
};


export type DataMarketMutationAdminCreateProductArgs = {
  command: CreateProductCommand;
};


export type DataMarketMutationAdminUpdateProductArgs = {
  command: CreateProductCommand;
};


export type DataMarketMutationAdminDeleteProductArgs = {
  id: Scalars['ID'];
};


export type DataMarketMutationAdminSetHttpSourceArgs = {
  command: SetHttpDataSourceCommand;
};


export type DataMarketMutationAdminDeleteHttpSourceArgs = {
  dataProductId: Scalars['ID'];
};


export type DataMarketMutationDeleteSubscriptionArgs = {
  id: Scalars['ID'];
};


export type DataMarketMutationCreateApiKeyArgs = {
  command: CreateDataProductPlanSubscriptionApiKey;
};


export type DataMarketMutationDeleteApiKeyArgs = {
  subscriptionId: Scalars['ID'];
  keyId: Scalars['ID'];
};

export type DataProduct = {
  __typename?: 'DataProduct';
  id: Scalars['ID'];
  dataType: DataProductDataType;
  openDataSpecification: Scalars['Json'];
  active: Scalars['Boolean'];
  httpSource?: Maybe<DataProductHttpSource>;
};

export enum DataProductDataType {
  Personal = 'PERSONAL',
  Common = 'COMMON'
}

export type DataProductHttpSource = {
  __typename?: 'DataProductHttpSource';
  scheme: HttpScheme;
  host: Scalars['String'];
  port: Scalars['Int'];
  basePath: Scalars['String'];
  forwardedHostname?: Maybe<Scalars['String']>;
  forwardedHeaders: Array<ForwardedHeader>;
};

export type DataProductPlan = {
  __typename?: 'DataProductPlan';
  id: Scalars['ID'];
};

export type DataProductPlanSubscription = {
  __typename?: 'DataProductPlanSubscription';
  id: Scalars['ID'];
  product: DataProduct;
  planId: Scalars['ID'];
  active: Scalars['Boolean'];
  purpose: Scalars['String'];
  keys: Array<DataProductPlanSubscriptionApiKey>;
};

export type DataProductPlanSubscriptionApiKey = {
  __typename?: 'DataProductPlanSubscriptionApiKey';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  key: Scalars['String'];
};

export type DeleteTreeCommand = {
  id: Scalars['ID'];
  reason: DeleteTreeReason;
};

export enum DeleteTreeReason {
  Fallen = 'FALLEN',
  Felled = 'FELLED',
  Unknown = 'UNKNOWN',
  DoesNotExist = 'DOES_NOT_EXIST'
}

export type ForwardedHeader = {
  __typename?: 'ForwardedHeader';
  header: Scalars['String'];
  value: Scalars['String'];
};

export type ForwardedHeaderCommand = {
  header: Scalars['String'];
  value: Scalars['String'];
};

export enum HeightInMeterClass {
  ZeroToThree = 'ZERO_TO_THREE',
  ThreeToSix = 'THREE_TO_SIX',
  SixToNine = 'SIX_TO_NINE',
  NineToTwelve = 'NINE_TO_TWELVE',
  TwelveToFifteen = 'TWELVE_TO_FIFTEEN',
  FifteenToEighteen = 'FIFTEEN_TO_EIGHTEEN',
  EighteenToTwentyOne = 'EIGHTEEN_TO_TWENTY_ONE',
  TwentyOneToTwentyFour = 'TWENTY_ONE_TO_TWENTY_FOUR',
  TwentyFourToTwentySeven = 'TWENTY_FOUR_TO_TWENTY_SEVEN',
  TwentySevenToThirty = 'TWENTY_SEVEN_TO_THIRTY',
  AboveThirty = 'ABOVE_THIRTY'
}

export enum HttpScheme {
  Http = 'HTTP',
  Https = 'HTTPS'
}

export type HubspotContactList = {
  __typename?: 'HubspotContactList';
  id: Scalars['Int'];
  name: Scalars['String'];
  contactCount: Scalars['Int'];
};

export type HubspotMutation = {
  __typename?: 'HubspotMutation';
  /** Imports all the contacts from a Hubspot list as users into the given organization */
  importList: ImportHubspotListResult;
  /** Resends verification emails to all contacts in the hubspot contact list (if they exist as user) and returns a list of success/failure messages */
  sendVerificationEmails: Array<Scalars['String']>;
  /** Create sensor registration tokens for the users found in the list */
  createSensorRegistrationTokens: CreateSensorRegistrationTokensResult;
};


export type HubspotMutationImportListArgs = {
  command: ImportHubspotListCommand;
};


export type HubspotMutationSendVerificationEmailsArgs = {
  hubspotContactListId: Scalars['ID'];
};


export type HubspotMutationCreateSensorRegistrationTokensArgs = {
  command: CreateSensorRegistrationTokensCommand;
};

export type HubspotQuery = {
  __typename?: 'HubspotQuery';
  contactLists: Array<HubspotContactList>;
};

export type HumidityMeasurement = {
  __typename?: 'HumidityMeasurement';
  dateTime: Scalars['Instant'];
  /** Percentage of humidity expressed as float between 0 and 1. No measurement is expressed as null */
  value?: Maybe<Scalars['Float']>;
};

export type ImportHubspotListCommand = {
  listId: Scalars['ID'];
  userRoles: Array<RoleUser>;
  /** The orgId of the organization the user belongs to initially */
  orgId: Scalars['ID'];
  /** The roles the user will get in the organization with orgId. If the list is empty, this and orgId are ignored */
  organizationRoles: Array<RoleOrganization>;
};

export type ImportHubspotListResult = {
  __typename?: 'ImportHubspotListResult';
  importedUsers: Array<User>;
  errorMessages: Array<Scalars['String']>;
};

export type InputSpecies = {
  speciesFreeText?: InputMaybe<Scalars['String']>;
  speciesCode?: InputMaybe<Scalars['String']>;
};

export type Organization = {
  __typename?: 'Organization';
  id: Scalars['ID'];
  orgId: Scalars['ID'];
  name: Scalars['String'];
  users: Array<OrganizationUser>;
};

export type OrganizationUser = {
  __typename?: 'OrganizationUser';
  organization: Organization;
  user: User;
  organizationRoles: Array<RoleOrganization>;
};

export type OrganizationsMutation = {
  __typename?: 'OrganizationsMutation';
  createOrganization: Organization;
  updateOrganization: Organization;
};


export type OrganizationsMutationCreateOrganizationArgs = {
  command: CreateOrganizationCommand;
};


export type OrganizationsMutationUpdateOrganizationArgs = {
  command: UpdateOrganizationCommand;
};

export type PlatformAlert = {
  __typename?: 'PlatformAlert';
  id: Scalars['ID'];
  title: Scalars['String'];
  message: Scalars['String'];
};

export type PossibleHumidityMeasurement = HumidityMeasurement | SensorError;

export type PossibleTemperatureMeasurement = TemperatureMeasurement | SensorError;

export type RegisterSensoterraSensorCommand = {
  id?: InputMaybe<Scalars['ID']>;
  serial: Scalars['ID'];
  soilTypeId?: InputMaybe<Scalars['Int']>;
};

export enum RoleOrganization {
  OrgPientereTuinenManager = 'ORG_PIENTERE_TUINEN_MANAGER',
  OrgSensorRegisterManager = 'ORG_SENSOR_REGISTER_MANAGER',
  OrgSensorRegisterUser = 'ORG_SENSOR_REGISTER_USER',
  OrgDataMarketUser = 'ORG_DATA_MARKET_USER',
  OrgDataMarketSubscriptionManager = 'ORG_DATA_MARKET_SUBSCRIPTION_MANAGER'
}

export enum RoleUser {
  UserGlobalAdmin = 'USER_GLOBAL_ADMIN',
  UserApiCatalogUser = 'USER_API_CATALOG_USER',
  UserPientereTuinenUser = 'USER_PIENTERE_TUINEN_USER',
  UserTreeMonitorUser = 'USER_TREE_MONITOR_USER'
}

export type Sensor = {
  id: Scalars['ID'];
};

export type SensorError = {
  __typename?: 'SensorError';
  dateTime?: Maybe<Scalars['Instant']>;
  type: SensorErrorType;
};

export enum SensorErrorType {
  /** The measurement has a unit that we did not expect */
  UnexpectedUnit = 'UNEXPECTED_UNIT',
  /** The measured value is so far out of the expected range that the sensor is probably faulty */
  ValueOutOfExpectedRange = 'VALUE_OUT_OF_EXPECTED_RANGE'
}

/**
 * Used as a temporary map between user and org so we know when a user tries to register a sensor, what org they should
 * belong to
 */
export type SensorRegistrationToken = {
  __typename?: 'SensorRegistrationToken';
  user?: Maybe<User>;
  organization?: Maybe<Organization>;
};

export type SensorsMutation = {
  __typename?: 'SensorsMutation';
  registerSensoterraSensor: SensoterraSensor;
  deleteMyRegistrationToken?: Maybe<Scalars['ID']>;
};


export type SensorsMutationRegisterSensoterraSensorArgs = {
  command: RegisterSensoterraSensorCommand;
};

export type SensoterraMeasurements = {
  __typename?: 'SensoterraMeasurements';
  humidity: Array<PossibleHumidityMeasurement>;
  temperature: Array<PossibleTemperatureMeasurement>;
};

export type SensoterraMetric = {
  __typename?: 'SensoterraMetric';
  timestamp?: Maybe<Scalars['Instant']>;
  height?: Maybe<Scalars['Int']>;
  unit?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['Float']>;
};

export enum SensoterraMetricAggregate {
  Hour = 'HOUR',
  Day = 'DAY'
}

export type SensoterraQuery = {
  from: Scalars['Instant'];
  to: Scalars['Instant'];
  offset?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
  aggregate?: InputMaybe<SensoterraMetricAggregate>;
};

export type SensoterraSensor = Sensor & {
  __typename?: 'SensoterraSensor';
  id: Scalars['ID'];
  state?: Maybe<Scalars['String']>;
  depthId?: Maybe<Scalars['Int']>;
  status?: Maybe<SensoterraStatus>;
  data: Array<SensoterraMetric>;
  measurements: SensoterraMeasurements;
};


export type SensoterraSensorDataArgs = {
  from: Scalars['Instant'];
  to: Scalars['Instant'];
  offset?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
  aggregate?: InputMaybe<SensoterraMetricAggregate>;
};


export type SensoterraSensorMeasurementsArgs = {
  query: SensoterraQuery;
};

export type SensoterraStatus = {
  __typename?: 'SensoterraStatus';
  battery?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Instant']>;
};

export type SetHttpDataSourceCommand = {
  dataProductId: Scalars['ID'];
  scheme: HttpScheme;
  host: Scalars['String'];
  port: Scalars['Int'];
  basePath: Scalars['String'];
  forwardedHostname?: InputMaybe<Scalars['String']>;
  forwardedHeaders: Array<ForwardedHeaderCommand>;
};

export type SetOrganizationRolesCommand = {
  userId: Scalars['ID'];
  orgId: Scalars['ID'];
  roles: Array<RoleOrganization>;
};

export type SetUserRolesCommand = {
  userId: Scalars['ID'];
  roles: Array<RoleUser>;
};

export type TemperatureMeasurement = {
  __typename?: 'TemperatureMeasurement';
  dateTime: Scalars['Instant'];
  /** No measurement is expressed as null */
  value?: Maybe<Scalars['Float']>;
  unit?: Maybe<TemperatureUnit>;
};

export enum TemperatureUnit {
  Celsius = 'CELSIUS',
  Fahrenheit = 'FAHRENHEIT'
}

export type Tree = {
  __typename?: 'Tree';
  id: Scalars['ID'];
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
  sizes: TreeSizes;
  benefits?: Maybe<TreeBenefits>;
  species?: Maybe<TreeSpecies>;
};

export type TreeBenefits = {
  __typename?: 'TreeBenefits';
  worthInEuro?: Maybe<Scalars['Float']>;
  yearlyCapturedCo2InKg?: Maybe<Scalars['Float']>;
  yearlyCapturedPollutionInG?: Maybe<Scalars['Float']>;
  yearlyCapturedWaterInM3?: Maybe<Scalars['Float']>;
};

export type TreeSearchQuery = {
  minLatitude: Scalars['Float'];
  minLongitude: Scalars['Float'];
  maxLatitude: Scalars['Float'];
  maxLongitude: Scalars['Float'];
  limit?: InputMaybe<Scalars['Int']>;
};

export type TreeSizes = {
  __typename?: 'TreeSizes';
  trunkDiameterInCm?: Maybe<Scalars['Int']>;
  heightClass?: Maybe<HeightInMeterClass>;
  crownDiameterClass?: Maybe<HeightInMeterClass>;
  crownVolumeInM3?: Maybe<Scalars['Float']>;
  preferredCrownCutHeightInM?: Maybe<Scalars['Float']>;
};

export type TreeSpecies = {
  __typename?: 'TreeSpecies';
  name?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  scientificName?: Maybe<Scalars['String']>;
  cropType?: Maybe<Scalars['String']>;
};

export type Trees = {
  __typename?: 'Trees';
  tree: Tree;
  treeSearch: Array<Tree>;
  treeSpecies: Array<TreeSpecies>;
};


export type TreesTreeArgs = {
  id: Scalars['ID'];
};


export type TreesTreeSearchArgs = {
  query: TreeSearchQuery;
};

export type TreesMutation = {
  __typename?: 'TreesMutation';
  createTree: Tree;
  updateTree: Tree;
  deleteTree: Scalars['ID'];
};


export type TreesMutationCreateTreeArgs = {
  command: CreateTreeCommand;
};


export type TreesMutationUpdateTreeArgs = {
  command: UpdateTreeCommand;
};


export type TreesMutationDeleteTreeArgs = {
  command?: InputMaybe<DeleteTreeCommand>;
};

export type UpdateOrganizationCommand = {
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type UpdateProductCommand = {
  id: Scalars['ID'];
  openDataProductSpecification: Scalars['Json'];
  active: Scalars['Boolean'];
};

export type UpdateTreeCommand = {
  id: Scalars['ID'];
  inputSpecies: InputSpecies;
  heightClass: HeightInMeterClass;
  crownDiameterClass: HeightInMeterClass;
  trunkDiameterInCm?: InputMaybe<Scalars['Int']>;
  latitude?: InputMaybe<Scalars['Float']>;
  longitude?: InputMaybe<Scalars['Float']>;
};

export type UpdateUserCommand = {
  id: Scalars['ID'];
  firstname: Scalars['String'];
  lastname: Scalars['String'];
  email: Scalars['String'];
};

export type User = {
  __typename?: 'User';
  id: Scalars['ID'];
  createdAt: Scalars['Instant'];
  updatedAt: Scalars['Instant'];
  firstname: Scalars['String'];
  lastname: Scalars['String'];
  email: Scalars['String'];
  userRoles: Array<RoleUser>;
  organizations: Array<OrganizationUser>;
  /** This is an expensive call since we need to reach out to our IAM provider */
  isEmailVerified: Scalars['Boolean'];
};

export type UserSearchQuery = {
  /** String to search for in firstname, lastname and email. Minimum 3 characters */
  searchString: Scalars['String'];
};

export type UserSummary = {
  __typename?: 'UserSummary';
  id: Scalars['ID'];
  createdAt: Scalars['Instant'];
  updatedAt: Scalars['Instant'];
  firstname: Scalars['String'];
  lastname: Scalars['String'];
  email: Scalars['String'];
};

export type UsersMutation = {
  __typename?: 'UsersMutation';
  createUser: User;
  updateUser: User;
  sendEmailVerification: Scalars['Boolean'];
  setUserRoles: Array<RoleUser>;
  bulkAddUserRoles: Scalars['Boolean'];
  bulkDeleteUserRoles: Scalars['Boolean'];
  setOrganizationRoles: Array<RoleOrganization>;
  bulkAddOrganizationRoles: Scalars['Boolean'];
  bulkDeleteOrganizationRoles: Scalars['Boolean'];
};


export type UsersMutationCreateUserArgs = {
  command: CreateUserCommand;
};


export type UsersMutationUpdateUserArgs = {
  command: UpdateUserCommand;
};


export type UsersMutationSendEmailVerificationArgs = {
  id: Scalars['ID'];
};


export type UsersMutationSetUserRolesArgs = {
  command: SetUserRolesCommand;
};


export type UsersMutationBulkAddUserRolesArgs = {
  command: BulkUserRolesCommand;
};


export type UsersMutationBulkDeleteUserRolesArgs = {
  command: BulkUserRolesCommand;
};


export type UsersMutationSetOrganizationRolesArgs = {
  command: SetOrganizationRolesCommand;
};


export type UsersMutationBulkAddOrganizationRolesArgs = {
  command: BulkOrganizationRolesCommand;
};


export type UsersMutationBulkDeleteOrganizationRolesArgs = {
  command: BulkOrganizationRolesCommand;
};

export type GetPientereTuinenSchemeQueryVariables = Exact<{ [key: string]: never; }>;


export type GetPientereTuinenSchemeQuery = { __typename?: 'Query', scheme?: { __typename?: 'SchemeResult', data?: { __typename?: 'Scheme', name?: string | null, propertyTypes?: Array<{ __typename?: 'PropertyType', name?: string | null, validation?: string | null, description?: string | null }> | null } | null } | null };

export type GetPientereTuinenAssetsForUserQueryVariables = Exact<{ [key: string]: never; }>;


export type GetPientereTuinenAssetsForUserQuery = { __typename?: 'Query', pientereTuinenAssetsForUser: { __typename?: 'AssetsResult', data?: Array<{ __typename?: 'Asset', id: string, name?: string | null, createdAt: any, updatedAt: any, properties?: Array<{ __typename?: 'Property', id: string, key?: string | null, value?: string | null, type?: string | null }> | null }> | null } };

export type CreatePientereTuinenPropertyMutationVariables = Exact<{
  command: CreatePropertyCommand;
}>;


export type CreatePientereTuinenPropertyMutation = { __typename?: 'Mutation', createPientereTuinenProperty: { __typename?: 'PropertyResult', data: { __typename?: 'Property', id: string, key?: string | null, type?: string | null, value?: string | null } } };

export type UpdatePientereTuinenPropertyMutationVariables = Exact<{
  command: UpdatePropertyCommand;
}>;


export type UpdatePientereTuinenPropertyMutation = { __typename?: 'Mutation', updatePientereTuinenProperty: { __typename?: 'PropertyResult', data: { __typename?: 'Property', id: string, key?: string | null, type?: string | null, value?: string | null } } };

export type CreatePientereTuinenAssetMutationVariables = Exact<{
  command: CreateAssetCommand;
}>;


export type CreatePientereTuinenAssetMutation = { __typename?: 'Mutation', createPientereTuinenAsset: { __typename?: 'AssetResult', data: { __typename?: 'Asset', id: string, name?: string | null, createdAt: any } } };

export type UpdatePientereTuinenAssetMutationVariables = Exact<{
  command: UpdateAssetCommand;
}>;


export type UpdatePientereTuinenAssetMutation = { __typename?: 'Mutation', updatePientereTuinenAsset: { __typename?: 'AssetResult', data: { __typename?: 'Asset', id: string, name?: string | null, properties?: Array<{ __typename?: 'Property', id: string, key?: string | null, value?: string | null, type?: string | null }> | null } } };

export type AdminGetAssetsForUserQueryVariables = Exact<{
  userId: Scalars['ID'];
}>;


export type AdminGetAssetsForUserQuery = { __typename?: 'Query', adminAssetsForUser?: { __typename?: 'AssetsResult', data?: Array<{ __typename?: 'Asset', id: string, name?: string | null, createdAt: any, updatedAt: any, properties?: Array<{ __typename?: 'Property', id: string, key?: string | null, value?: string | null, type?: string | null }> | null }> | null } | null };

export type AdminDeleteAssetMutationVariables = Exact<{
  assetId: Scalars['ID'];
}>;


export type AdminDeleteAssetMutation = { __typename?: 'Mutation', adminDeleteAsset: { __typename?: 'AssetResult', data: { __typename?: 'Asset', id: string } } };

export type AdminSearchAssetsQueryVariables = Exact<{
  searchQuery?: InputMaybe<Scalars['String']>;
}>;


export type AdminSearchAssetsQuery = { __typename?: 'Query', adminSearchAssets: { __typename?: 'AssetsResult', data?: Array<{ __typename?: 'Asset', id: string, name?: string | null, createdAt: any, updatedAt: any, properties?: Array<{ __typename?: 'Property', id: string, key?: string | null, value?: string | null, type?: string | null }> | null }> | null, meta?: { __typename?: 'PaginationMeta', currentPage: number, lastPage?: number | null, perPage: number, from?: number | null, to?: number | null, total: number, links?: Array<{ __typename?: 'PaginationLink', url: string, active: boolean, label?: string | null }> | null } | null } };

export type GetApisQueryVariables = Exact<{ [key: string]: never; }>;


export type GetApisQuery = { __typename?: 'Query', apiCatalog: { __typename?: 'ApiCatalog', apis: Array<{ __typename?: 'Api', name: ApiName, plans: Array<{ __typename?: 'ApiPlan', slug: string }> }> } };

export type GetApiSubscriptionsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetApiSubscriptionsQuery = { __typename?: 'Query', apiCatalog: { __typename?: 'ApiCatalog', userSubscriptions: Array<{ __typename?: 'ApiSubscriptionUser', id: string, createdAt: any, api: { __typename?: 'Api', name: ApiName }, plan: { __typename?: 'ApiPlan', slug: string }, keys: Array<{ __typename?: 'ApiKey', id: string, key: string, createdAt: any, updatedAt: any }> }> } };

export type GetApiCatalogMeteringQueryVariables = Exact<{
  where: ApiCatalogMeteringWhereInput;
}>;


export type GetApiCatalogMeteringQuery = { __typename?: 'Query', cube: Array<{ __typename?: 'Result', api_catalog_metering: { __typename?: 'ApiCatalogMeteringMembers', sum_calls?: number | null, day?: { __typename?: 'TimeDimension', day: any } | null } }> };

export type CreateApiSubscriptionMutationVariables = Exact<{
  command: CreateUserApiSubscriptionCommand;
}>;


export type CreateApiSubscriptionMutation = { __typename?: 'Mutation', apiCatalog: { __typename?: 'ApiCatalogMutation', createUserSubscription: { __typename?: 'ApiSubscriptionUser', id: string, createdAt: any, api: { __typename?: 'Api', name: ApiName }, plan: { __typename?: 'ApiPlan', slug: string }, keys: Array<{ __typename?: 'ApiKey', id: string, key: string, createdAt: any, updatedAt: any }> } } };

export type CreateApiSubscriptionKeyMutationVariables = Exact<{
  subscriptionId: Scalars['ID'];
}>;


export type CreateApiSubscriptionKeyMutation = { __typename?: 'Mutation', apiCatalog: { __typename?: 'ApiCatalogMutation', createUserApiKey: Array<{ __typename?: 'ApiKey', id: string, key: string, createdAt: any, updatedAt: any }> } };

export type DeleteApiSubscriptionKeyMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteApiSubscriptionKeyMutation = { __typename?: 'Mutation', apiCatalog: { __typename?: 'ApiCatalogMutation', deleteUserApiKey: string } };

export type DeleteApiSubscriptionMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteApiSubscriptionMutation = { __typename?: 'Mutation', apiCatalog: { __typename?: 'ApiCatalogMutation', deleteUserSubscription: string } };

export type GetHubspotContactListsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetHubspotContactListsQuery = { __typename?: 'Query', hubspot: { __typename?: 'HubspotQuery', contactLists: Array<{ __typename?: 'HubspotContactList', id: number, name: string, contactCount: number }> } };

export type ImportHubspotListMutationVariables = Exact<{
  command: ImportHubspotListCommand;
}>;


export type ImportHubspotListMutation = { __typename?: 'Mutation', hubspot: { __typename?: 'HubspotMutation', importList: { __typename?: 'ImportHubspotListResult', errorMessages: Array<string>, importedUsers: Array<{ __typename?: 'User', id: string, email: string, firstname: string, lastname: string }> } } };

export type SendVerificationEmailMutationVariables = Exact<{
  hubspotContactListId: Scalars['ID'];
}>;


export type SendVerificationEmailMutation = { __typename?: 'Mutation', hubspot: { __typename?: 'HubspotMutation', sendVerificationEmails: Array<string> } };

export type CreateSensorRegistrationTokensMutationVariables = Exact<{
  command: CreateSensorRegistrationTokensCommand;
}>;


export type CreateSensorRegistrationTokensMutation = { __typename?: 'Mutation', hubspot: { __typename?: 'HubspotMutation', createSensorRegistrationTokens: { __typename?: 'CreateSensorRegistrationTokensResult', errorMessages: Array<string>, createdTokensForUsers: Array<{ __typename?: 'User', id: string, email: string, firstname: string, lastname: string }> } } };

export type GetOrganizationsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetOrganizationsQuery = { __typename?: 'Query', organizations: Array<{ __typename?: 'Organization', id: string, name: string, orgId: string }> };

export type GetOrganizationWithUsersQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetOrganizationWithUsersQuery = { __typename?: 'Query', organization: { __typename?: 'Organization', id: string, name: string, orgId: string, users: Array<{ __typename?: 'OrganizationUser', user: { __typename?: 'User', id: string, createdAt: any, updatedAt: any, firstname: string, lastname: string, email: string } }> } };

export type CreateOrganizationMutationVariables = Exact<{
  command: CreateOrganizationCommand;
}>;


export type CreateOrganizationMutation = { __typename?: 'Mutation', organizations: { __typename?: 'OrganizationsMutation', createOrganization: { __typename?: 'Organization', id: string, name: string, orgId: string } } };

export type CreateGroupMutationVariables = Exact<{
  command: CreateGroupCommand;
}>;


export type CreateGroupMutation = { __typename?: 'Mutation', adminCreateGroup: { __typename?: 'GroupResult', data: { __typename?: 'Group', id: string, name?: string | null } } };

export type GetRegisteredSensorSchemeQueryVariables = Exact<{ [key: string]: never; }>;


export type GetRegisteredSensorSchemeQuery = { __typename?: 'Query', scheme?: { __typename?: 'SchemeResult', data?: { __typename?: 'Scheme', name?: string | null, propertyTypes?: Array<{ __typename?: 'PropertyType', name?: string | null, validation?: string | null, description?: string | null }> | null } | null } | null };

export type PaginateRegisteredSensorAssetsQueryVariables = Exact<{
  filterQuery?: InputMaybe<Scalars['String']>;
}>;


export type PaginateRegisteredSensorAssetsQuery = { __typename?: 'Query', registeredSensorAssets: { __typename?: 'AssetsResult', data?: Array<{ __typename?: 'Asset', id: string, name?: string | null, createdAt: any, updatedAt: any, properties?: Array<{ __typename?: 'Property', id: string, key?: string | null, value?: string | null, type?: string | null }> | null }> | null, meta?: { __typename?: 'PaginationMeta', currentPage: number, lastPage?: number | null, perPage: number, from?: number | null, to?: number | null, total: number, links?: Array<{ __typename?: 'PaginationLink', url: string, active: boolean, label?: string | null }> | null } | null } };

export type SearchRegisteredSensorAssetsQueryVariables = Exact<{
  searchQuery?: InputMaybe<Scalars['String']>;
}>;


export type SearchRegisteredSensorAssetsQuery = { __typename?: 'Query', searchRegisteredSensorAssets: { __typename?: 'AssetsResult', data?: Array<{ __typename?: 'Asset', id: string, name?: string | null, createdAt: any, updatedAt: any, properties?: Array<{ __typename?: 'Property', id: string, key?: string | null, value?: string | null, type?: string | null }> | null }> | null, meta?: { __typename?: 'PaginationMeta', currentPage: number, lastPage?: number | null, perPage: number, from?: number | null, to?: number | null, total: number, links?: Array<{ __typename?: 'PaginationLink', url: string, active: boolean, label?: string | null }> | null } | null } };

export type GetRegisteredSensorAssetQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetRegisteredSensorAssetQuery = { __typename?: 'Query', registeredSensorAsset: { __typename?: 'AssetResult', data: { __typename?: 'Asset', id: string, name?: string | null, createdAt: any, updatedAt: any, properties?: Array<{ __typename?: 'Property', id: string, key?: string | null, value?: string | null, type?: string | null }> | null } } };

export type CreateRegisteredSensorAssetMutationVariables = Exact<{
  command: CreateAssetCommand;
}>;


export type CreateRegisteredSensorAssetMutation = { __typename?: 'Mutation', createRegisteredSensorAsset: { __typename?: 'AssetResult', data: { __typename?: 'Asset', id: string, name?: string | null, createdAt: any } } };

export type BulkCreateRegisteredSensorAssetMutationVariables = Exact<{
  command: Array<InputMaybe<CreateAssetCommand>> | InputMaybe<CreateAssetCommand>;
}>;


export type BulkCreateRegisteredSensorAssetMutation = { __typename?: 'Mutation', bulkCreateRegisteredSensorAssets: { __typename?: 'AssetsResult', data?: Array<{ __typename?: 'Asset', id: string, name?: string | null, createdAt: any }> | null } };

export type UpdateRegisteredSensorAssetMutationVariables = Exact<{
  command: UpdateAssetCommand;
}>;


export type UpdateRegisteredSensorAssetMutation = { __typename?: 'Mutation', updateRegisteredSensorAsset: { __typename?: 'AssetResult', data: { __typename?: 'Asset', id: string, name?: string | null, properties?: Array<{ __typename?: 'Property', id: string, key?: string | null, value?: string | null, type?: string | null }> | null } } };

export type DeleteRegisteredSensorAssetMutationVariables = Exact<{
  assetId: Scalars['ID'];
}>;


export type DeleteRegisteredSensorAssetMutation = { __typename?: 'Mutation', deleteRegisteredSensorAsset: { __typename?: 'AssetResult', data: { __typename?: 'Asset', id: string } } };

export type UpdateRegisteredSensorPropertyMutationVariables = Exact<{
  command: UpdatePropertyCommand;
}>;


export type UpdateRegisteredSensorPropertyMutation = { __typename?: 'Mutation', updateRegisteredSensorProperty: { __typename?: 'PropertyResult', data: { __typename?: 'Property', id: string, key?: string | null, type?: string | null, value?: string | null } } };

export type DeleteRegisteredSensorPropertyMutationVariables = Exact<{
  command: DeletePropertyCommand;
}>;


export type DeleteRegisteredSensorPropertyMutation = { __typename?: 'Mutation', deleteRegisteredSensorProperty: { __typename?: 'PropertyResult', data: { __typename?: 'Property', id: string } } };

export type GetPlatformAlertsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetPlatformAlertsQuery = { __typename?: 'Query', platformAlerts: Array<{ __typename?: 'PlatformAlert', id: string, title: string, message: string }> };

export type TreePartsFragment = { __typename?: 'Tree', id: string, latitude: number, longitude: number, benefits?: { __typename?: 'TreeBenefits', worthInEuro?: number | null, yearlyCapturedCo2InKg?: number | null, yearlyCapturedPollutionInG?: number | null, yearlyCapturedWaterInM3?: number | null } | null, sizes: { __typename?: 'TreeSizes', crownVolumeInM3?: number | null, heightClass?: HeightInMeterClass | null, crownDiameterClass?: HeightInMeterClass | null, trunkDiameterInCm?: number | null, preferredCrownCutHeightInM?: number | null }, species?: { __typename?: 'TreeSpecies', name?: string | null, code?: string | null, scientificName?: string | null, cropType?: string | null } | null };

export type SearchTreesQueryVariables = Exact<{
  query: TreeSearchQuery;
}>;


export type SearchTreesQuery = { __typename?: 'Query', trees: { __typename?: 'Trees', treeSearch: Array<{ __typename?: 'Tree', id: string, latitude: number, longitude: number, benefits?: { __typename?: 'TreeBenefits', worthInEuro?: number | null, yearlyCapturedCo2InKg?: number | null, yearlyCapturedPollutionInG?: number | null, yearlyCapturedWaterInM3?: number | null } | null, sizes: { __typename?: 'TreeSizes', crownVolumeInM3?: number | null, heightClass?: HeightInMeterClass | null, crownDiameterClass?: HeightInMeterClass | null, trunkDiameterInCm?: number | null, preferredCrownCutHeightInM?: number | null }, species?: { __typename?: 'TreeSpecies', name?: string | null, code?: string | null, scientificName?: string | null, cropType?: string | null } | null }> } };

export type GetTreeSpeciesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetTreeSpeciesQuery = { __typename?: 'Query', trees: { __typename?: 'Trees', treeSpecies: Array<{ __typename?: 'TreeSpecies', name?: string | null, code?: string | null, scientificName?: string | null }> } };

export type CreateTreeMutationVariables = Exact<{
  command: CreateTreeCommand;
}>;


export type CreateTreeMutation = { __typename?: 'Mutation', trees: { __typename?: 'TreesMutation', createTree: { __typename?: 'Tree', id: string, latitude: number, longitude: number, benefits?: { __typename?: 'TreeBenefits', worthInEuro?: number | null, yearlyCapturedCo2InKg?: number | null, yearlyCapturedPollutionInG?: number | null, yearlyCapturedWaterInM3?: number | null } | null, sizes: { __typename?: 'TreeSizes', crownVolumeInM3?: number | null, heightClass?: HeightInMeterClass | null, crownDiameterClass?: HeightInMeterClass | null, trunkDiameterInCm?: number | null, preferredCrownCutHeightInM?: number | null }, species?: { __typename?: 'TreeSpecies', name?: string | null, code?: string | null, scientificName?: string | null, cropType?: string | null } | null } } };

export type UpdateTreeMutationVariables = Exact<{
  command: UpdateTreeCommand;
}>;


export type UpdateTreeMutation = { __typename?: 'Mutation', trees: { __typename?: 'TreesMutation', updateTree: { __typename?: 'Tree', id: string, latitude: number, longitude: number, benefits?: { __typename?: 'TreeBenefits', worthInEuro?: number | null, yearlyCapturedCo2InKg?: number | null, yearlyCapturedPollutionInG?: number | null, yearlyCapturedWaterInM3?: number | null } | null, sizes: { __typename?: 'TreeSizes', crownVolumeInM3?: number | null, heightClass?: HeightInMeterClass | null, crownDiameterClass?: HeightInMeterClass | null, trunkDiameterInCm?: number | null, preferredCrownCutHeightInM?: number | null }, species?: { __typename?: 'TreeSpecies', name?: string | null, code?: string | null, scientificName?: string | null, cropType?: string | null } | null } } };

export type DeleteTreeMutationVariables = Exact<{
  command: DeleteTreeCommand;
}>;


export type DeleteTreeMutation = { __typename?: 'Mutation', trees: { __typename?: 'TreesMutation', deleteTree: string } };

export type GetSensorDataQueryVariables = Exact<{
  id: Scalars['ID'];
  query: SensoterraQuery;
}>;


export type GetSensorDataQuery = { __typename?: 'Query', sensoterraSensor?: { __typename?: 'SensoterraSensor', id: string, state?: string | null, status?: { __typename?: 'SensoterraStatus', battery?: string | null, updatedAt?: any | null } | null, measurements: { __typename: 'SensoterraMeasurements', humidity: Array<{ __typename?: 'HumidityMeasurement', value?: number | null, dateTime: any } | { __typename?: 'SensorError', type: SensorErrorType }>, temperature: Array<{ __typename?: 'TemperatureMeasurement', value?: number | null, dateTime: any, unit?: TemperatureUnit | null } | { __typename?: 'SensorError', type: SensorErrorType }> } } | null };

export type GetDailySensorDataCubeQueryQueryVariables = Exact<{
  where: PientereTuinenMeasurementsWhereInput;
}>;


export type GetDailySensorDataCubeQueryQuery = { __typename?: 'Query', cube: Array<{ __typename?: 'Result', pientere_tuinen_measurements: { __typename?: 'PientereTuinenMeasurementsMembers', user_id?: string | null, avg_temperature_celsius?: number | null, avg_moisture_percentage?: number | null, measured_at?: { __typename?: 'TimeDimension', day: any } | null } }> };

export type GetHourlySensorDataCubeQueryQueryVariables = Exact<{
  where: PientereTuinenMeasurementsWhereInput;
}>;


export type GetHourlySensorDataCubeQueryQuery = { __typename?: 'Query', cube: Array<{ __typename?: 'Result', pientere_tuinen_measurements: { __typename?: 'PientereTuinenMeasurementsMembers', user_id?: string | null, avg_temperature_celsius?: number | null, avg_moisture_percentage?: number | null, measured_at?: { __typename?: 'TimeDimension', hour: any } | null } }> };

export type MySensorRegistrationTokenQueryVariables = Exact<{ [key: string]: never; }>;


export type MySensorRegistrationTokenQuery = { __typename?: 'Query', mySensorRegistrationToken?: { __typename?: 'SensorRegistrationToken', user?: { __typename?: 'User', id: string, email: string } | null, organization?: { __typename?: 'Organization', id: string, orgId: string } | null } | null };

export type DeleteMySensorRegistrationTokenMutationVariables = Exact<{ [key: string]: never; }>;


export type DeleteMySensorRegistrationTokenMutation = { __typename?: 'Mutation', sensors: { __typename?: 'SensorsMutation', deleteMyRegistrationToken?: string | null } };

export type RegisterSensoterraSensorMutationVariables = Exact<{
  command: RegisterSensoterraSensorCommand;
}>;


export type RegisterSensoterraSensorMutation = { __typename?: 'Mutation', sensors: { __typename?: 'SensorsMutation', registerSensoterraSensor: { __typename?: 'SensoterraSensor', id: string } } };

export type SearchUsersQueryVariables = Exact<{
  query: UserSearchQuery;
}>;


export type SearchUsersQuery = { __typename?: 'Query', userSearch: Array<{ __typename?: 'UserSummary', id: string, createdAt: any, updatedAt: any, firstname: string, lastname: string, email: string }> };

export type GetUserDetailsQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetUserDetailsQuery = { __typename?: 'Query', user: { __typename?: 'User', id: string, createdAt: any, updatedAt: any, firstname: string, lastname: string, email: string, userRoles: Array<RoleUser>, isEmailVerified: boolean, organizations: Array<{ __typename?: 'OrganizationUser', organizationRoles: Array<RoleOrganization>, organization: { __typename?: 'Organization', orgId: string, name: string } }> } };

export type GetMyUserRolesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetMyUserRolesQuery = { __typename?: 'Query', myUserRoles: Array<RoleUser> };

export type GetMyOrganizationRolesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetMyOrganizationRolesQuery = { __typename?: 'Query', myOrganizationRoles: Array<{ __typename?: 'OrganizationUser', organizationRoles: Array<RoleOrganization>, organization: { __typename?: 'Organization', orgId: string } }> };

export type SendEmailVerificationForUserMutationVariables = Exact<{
  userId: Scalars['ID'];
}>;


export type SendEmailVerificationForUserMutation = { __typename?: 'Mutation', users: { __typename?: 'UsersMutation', sendEmailVerification: boolean } };

export type UpdateUserMutationVariables = Exact<{
  command: UpdateUserCommand;
}>;


export type UpdateUserMutation = { __typename?: 'Mutation', users: { __typename?: 'UsersMutation', updateUser: { __typename?: 'User', id: string, firstname: string, lastname: string, email: string, updatedAt: any } } };

export type SetUserRolesMutationVariables = Exact<{
  command: SetUserRolesCommand;
}>;


export type SetUserRolesMutation = { __typename?: 'Mutation', users: { __typename?: 'UsersMutation', setUserRoles: Array<RoleUser> } };

export type SetOrganizationRolesMutationVariables = Exact<{
  command: SetOrganizationRolesCommand;
}>;


export type SetOrganizationRolesMutation = { __typename?: 'Mutation', users: { __typename?: 'UsersMutation', setOrganizationRoles: Array<RoleOrganization> } };

export const TreePartsFragmentDoc = gql`
    fragment TreeParts on Tree {
  id
  latitude
  longitude
  benefits {
    worthInEuro
    yearlyCapturedCo2InKg
    yearlyCapturedPollutionInG
    yearlyCapturedWaterInM3
  }
  sizes {
    crownVolumeInM3
    heightClass
    crownDiameterClass
    trunkDiameterInCm
    preferredCrownCutHeightInM
  }
  species {
    name
    code
    scientificName
    cropType
  }
}
    `;
export const GetPientereTuinenSchemeDocument = gql`
    query GetPientereTuinenScheme {
  scheme @rest(type: "SchemeResult", path: "/pientere-tuinen-sensor/scheme", method: "GET") {
    data {
      name
      propertyTypes {
        name
        validation
        description
      }
    }
  }
}
    `;

/**
 * __useGetPientereTuinenSchemeQuery__
 *
 * To run a query within a React component, call `useGetPientereTuinenSchemeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPientereTuinenSchemeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPientereTuinenSchemeQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPientereTuinenSchemeQuery(baseOptions?: Apollo.QueryHookOptions<GetPientereTuinenSchemeQuery, GetPientereTuinenSchemeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPientereTuinenSchemeQuery, GetPientereTuinenSchemeQueryVariables>(GetPientereTuinenSchemeDocument, options);
      }
export function useGetPientereTuinenSchemeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPientereTuinenSchemeQuery, GetPientereTuinenSchemeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPientereTuinenSchemeQuery, GetPientereTuinenSchemeQueryVariables>(GetPientereTuinenSchemeDocument, options);
        }
export type GetPientereTuinenSchemeQueryHookResult = ReturnType<typeof useGetPientereTuinenSchemeQuery>;
export type GetPientereTuinenSchemeLazyQueryHookResult = ReturnType<typeof useGetPientereTuinenSchemeLazyQuery>;
export type GetPientereTuinenSchemeQueryResult = Apollo.QueryResult<GetPientereTuinenSchemeQuery, GetPientereTuinenSchemeQueryVariables>;
export const GetPientereTuinenAssetsForUserDocument = gql`
    query GetPientereTuinenAssetsForUser {
  pientereTuinenAssetsForUser @rest(type: "AssetsResult", path: "/pientere-tuinen-sensor/assets/user", method: "GET") {
    data {
      id
      name
      createdAt
      updatedAt
      properties {
        id
        key
        value
        type
      }
    }
  }
}
    `;

/**
 * __useGetPientereTuinenAssetsForUserQuery__
 *
 * To run a query within a React component, call `useGetPientereTuinenAssetsForUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPientereTuinenAssetsForUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPientereTuinenAssetsForUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPientereTuinenAssetsForUserQuery(baseOptions?: Apollo.QueryHookOptions<GetPientereTuinenAssetsForUserQuery, GetPientereTuinenAssetsForUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPientereTuinenAssetsForUserQuery, GetPientereTuinenAssetsForUserQueryVariables>(GetPientereTuinenAssetsForUserDocument, options);
      }
export function useGetPientereTuinenAssetsForUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPientereTuinenAssetsForUserQuery, GetPientereTuinenAssetsForUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPientereTuinenAssetsForUserQuery, GetPientereTuinenAssetsForUserQueryVariables>(GetPientereTuinenAssetsForUserDocument, options);
        }
export type GetPientereTuinenAssetsForUserQueryHookResult = ReturnType<typeof useGetPientereTuinenAssetsForUserQuery>;
export type GetPientereTuinenAssetsForUserLazyQueryHookResult = ReturnType<typeof useGetPientereTuinenAssetsForUserLazyQuery>;
export type GetPientereTuinenAssetsForUserQueryResult = Apollo.QueryResult<GetPientereTuinenAssetsForUserQuery, GetPientereTuinenAssetsForUserQueryVariables>;
export const CreatePientereTuinenPropertyDocument = gql`
    mutation CreatePientereTuinenProperty($command: CreatePropertyCommand!) {
  createPientereTuinenProperty(input: $command) @rest(type: "PropertyResult", path: "/pientere-tuinen-sensor/assets/{args.input.assetId}/properties", method: "POST") {
    data {
      id
      key
      type
      value
    }
  }
}
    `;
export type CreatePientereTuinenPropertyMutationFn = Apollo.MutationFunction<CreatePientereTuinenPropertyMutation, CreatePientereTuinenPropertyMutationVariables>;

/**
 * __useCreatePientereTuinenPropertyMutation__
 *
 * To run a mutation, you first call `useCreatePientereTuinenPropertyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePientereTuinenPropertyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPientereTuinenPropertyMutation, { data, loading, error }] = useCreatePientereTuinenPropertyMutation({
 *   variables: {
 *      command: // value for 'command'
 *   },
 * });
 */
export function useCreatePientereTuinenPropertyMutation(baseOptions?: Apollo.MutationHookOptions<CreatePientereTuinenPropertyMutation, CreatePientereTuinenPropertyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePientereTuinenPropertyMutation, CreatePientereTuinenPropertyMutationVariables>(CreatePientereTuinenPropertyDocument, options);
      }
export type CreatePientereTuinenPropertyMutationHookResult = ReturnType<typeof useCreatePientereTuinenPropertyMutation>;
export type CreatePientereTuinenPropertyMutationResult = Apollo.MutationResult<CreatePientereTuinenPropertyMutation>;
export type CreatePientereTuinenPropertyMutationOptions = Apollo.BaseMutationOptions<CreatePientereTuinenPropertyMutation, CreatePientereTuinenPropertyMutationVariables>;
export const UpdatePientereTuinenPropertyDocument = gql`
    mutation UpdatePientereTuinenProperty($command: UpdatePropertyCommand!) {
  updatePientereTuinenProperty(input: $command) @rest(type: "PropertyResult", path: "/pientere-tuinen-sensor/assets/{args.input.assetId}/properties/{args.input.propertyId}", method: "PUT") {
    data {
      id
      key
      type
      value
    }
  }
}
    `;
export type UpdatePientereTuinenPropertyMutationFn = Apollo.MutationFunction<UpdatePientereTuinenPropertyMutation, UpdatePientereTuinenPropertyMutationVariables>;

/**
 * __useUpdatePientereTuinenPropertyMutation__
 *
 * To run a mutation, you first call `useUpdatePientereTuinenPropertyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePientereTuinenPropertyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePientereTuinenPropertyMutation, { data, loading, error }] = useUpdatePientereTuinenPropertyMutation({
 *   variables: {
 *      command: // value for 'command'
 *   },
 * });
 */
export function useUpdatePientereTuinenPropertyMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePientereTuinenPropertyMutation, UpdatePientereTuinenPropertyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePientereTuinenPropertyMutation, UpdatePientereTuinenPropertyMutationVariables>(UpdatePientereTuinenPropertyDocument, options);
      }
export type UpdatePientereTuinenPropertyMutationHookResult = ReturnType<typeof useUpdatePientereTuinenPropertyMutation>;
export type UpdatePientereTuinenPropertyMutationResult = Apollo.MutationResult<UpdatePientereTuinenPropertyMutation>;
export type UpdatePientereTuinenPropertyMutationOptions = Apollo.BaseMutationOptions<UpdatePientereTuinenPropertyMutation, UpdatePientereTuinenPropertyMutationVariables>;
export const CreatePientereTuinenAssetDocument = gql`
    mutation CreatePientereTuinenAsset($command: CreateAssetCommand!) {
  createPientereTuinenAsset(input: $command) @rest(type: "AssetResult", path: "/pientere-tuinen-sensor/assets", method: "POST") {
    data {
      id
      name
      createdAt
    }
  }
}
    `;
export type CreatePientereTuinenAssetMutationFn = Apollo.MutationFunction<CreatePientereTuinenAssetMutation, CreatePientereTuinenAssetMutationVariables>;

/**
 * __useCreatePientereTuinenAssetMutation__
 *
 * To run a mutation, you first call `useCreatePientereTuinenAssetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePientereTuinenAssetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPientereTuinenAssetMutation, { data, loading, error }] = useCreatePientereTuinenAssetMutation({
 *   variables: {
 *      command: // value for 'command'
 *   },
 * });
 */
export function useCreatePientereTuinenAssetMutation(baseOptions?: Apollo.MutationHookOptions<CreatePientereTuinenAssetMutation, CreatePientereTuinenAssetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePientereTuinenAssetMutation, CreatePientereTuinenAssetMutationVariables>(CreatePientereTuinenAssetDocument, options);
      }
export type CreatePientereTuinenAssetMutationHookResult = ReturnType<typeof useCreatePientereTuinenAssetMutation>;
export type CreatePientereTuinenAssetMutationResult = Apollo.MutationResult<CreatePientereTuinenAssetMutation>;
export type CreatePientereTuinenAssetMutationOptions = Apollo.BaseMutationOptions<CreatePientereTuinenAssetMutation, CreatePientereTuinenAssetMutationVariables>;
export const UpdatePientereTuinenAssetDocument = gql`
    mutation UpdatePientereTuinenAsset($command: UpdateAssetCommand!) {
  updatePientereTuinenAsset(input: $command) @rest(type: "AssetResult", path: "/pientere-tuinen-sensor/assets/{args.input.id}", method: "PATCH") {
    data {
      id
      name
      properties {
        id
        key
        value
        type
      }
    }
  }
}
    `;
export type UpdatePientereTuinenAssetMutationFn = Apollo.MutationFunction<UpdatePientereTuinenAssetMutation, UpdatePientereTuinenAssetMutationVariables>;

/**
 * __useUpdatePientereTuinenAssetMutation__
 *
 * To run a mutation, you first call `useUpdatePientereTuinenAssetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePientereTuinenAssetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePientereTuinenAssetMutation, { data, loading, error }] = useUpdatePientereTuinenAssetMutation({
 *   variables: {
 *      command: // value for 'command'
 *   },
 * });
 */
export function useUpdatePientereTuinenAssetMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePientereTuinenAssetMutation, UpdatePientereTuinenAssetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePientereTuinenAssetMutation, UpdatePientereTuinenAssetMutationVariables>(UpdatePientereTuinenAssetDocument, options);
      }
export type UpdatePientereTuinenAssetMutationHookResult = ReturnType<typeof useUpdatePientereTuinenAssetMutation>;
export type UpdatePientereTuinenAssetMutationResult = Apollo.MutationResult<UpdatePientereTuinenAssetMutation>;
export type UpdatePientereTuinenAssetMutationOptions = Apollo.BaseMutationOptions<UpdatePientereTuinenAssetMutation, UpdatePientereTuinenAssetMutationVariables>;
export const AdminGetAssetsForUserDocument = gql`
    query AdminGetAssetsForUser($userId: ID!) {
  adminAssetsForUser(userId: $userId) @rest(type: "AssetsResult", path: "/pientere-tuinen-sensor/assets/admin/user/{args.userId}", method: "GET") {
    data {
      id
      name
      createdAt
      updatedAt
      properties {
        id
        key
        value
        type
      }
    }
  }
}
    `;

/**
 * __useAdminGetAssetsForUserQuery__
 *
 * To run a query within a React component, call `useAdminGetAssetsForUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminGetAssetsForUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminGetAssetsForUserQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useAdminGetAssetsForUserQuery(baseOptions: Apollo.QueryHookOptions<AdminGetAssetsForUserQuery, AdminGetAssetsForUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AdminGetAssetsForUserQuery, AdminGetAssetsForUserQueryVariables>(AdminGetAssetsForUserDocument, options);
      }
export function useAdminGetAssetsForUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AdminGetAssetsForUserQuery, AdminGetAssetsForUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AdminGetAssetsForUserQuery, AdminGetAssetsForUserQueryVariables>(AdminGetAssetsForUserDocument, options);
        }
export type AdminGetAssetsForUserQueryHookResult = ReturnType<typeof useAdminGetAssetsForUserQuery>;
export type AdminGetAssetsForUserLazyQueryHookResult = ReturnType<typeof useAdminGetAssetsForUserLazyQuery>;
export type AdminGetAssetsForUserQueryResult = Apollo.QueryResult<AdminGetAssetsForUserQuery, AdminGetAssetsForUserQueryVariables>;
export const AdminDeleteAssetDocument = gql`
    mutation AdminDeleteAsset($assetId: ID!) {
  adminDeleteAsset(assetId: $assetId) @rest(type: "AssetResult", path: "/pientere-tuinen-sensor/assets/admin/{args.assetId}", method: "DELETE") {
    data {
      id
    }
  }
}
    `;
export type AdminDeleteAssetMutationFn = Apollo.MutationFunction<AdminDeleteAssetMutation, AdminDeleteAssetMutationVariables>;

/**
 * __useAdminDeleteAssetMutation__
 *
 * To run a mutation, you first call `useAdminDeleteAssetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminDeleteAssetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminDeleteAssetMutation, { data, loading, error }] = useAdminDeleteAssetMutation({
 *   variables: {
 *      assetId: // value for 'assetId'
 *   },
 * });
 */
export function useAdminDeleteAssetMutation(baseOptions?: Apollo.MutationHookOptions<AdminDeleteAssetMutation, AdminDeleteAssetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AdminDeleteAssetMutation, AdminDeleteAssetMutationVariables>(AdminDeleteAssetDocument, options);
      }
export type AdminDeleteAssetMutationHookResult = ReturnType<typeof useAdminDeleteAssetMutation>;
export type AdminDeleteAssetMutationResult = Apollo.MutationResult<AdminDeleteAssetMutation>;
export type AdminDeleteAssetMutationOptions = Apollo.BaseMutationOptions<AdminDeleteAssetMutation, AdminDeleteAssetMutationVariables>;
export const AdminSearchAssetsDocument = gql`
    query AdminSearchAssets($searchQuery: String) {
  adminSearchAssets(searchQuery: $searchQuery) @rest(type: "AssetsResult", path: "/pientere-tuinen-sensor/assets/admin/search{args.searchQuery}", method: "GET") {
    data {
      id
      name
      createdAt
      updatedAt
      properties {
        id
        key
        value
        type
      }
    }
    meta {
      currentPage
      lastPage
      perPage
      from
      to
      total
      links {
        url
        active
        label
      }
    }
  }
}
    `;

/**
 * __useAdminSearchAssetsQuery__
 *
 * To run a query within a React component, call `useAdminSearchAssetsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminSearchAssetsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminSearchAssetsQuery({
 *   variables: {
 *      searchQuery: // value for 'searchQuery'
 *   },
 * });
 */
export function useAdminSearchAssetsQuery(baseOptions?: Apollo.QueryHookOptions<AdminSearchAssetsQuery, AdminSearchAssetsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AdminSearchAssetsQuery, AdminSearchAssetsQueryVariables>(AdminSearchAssetsDocument, options);
      }
export function useAdminSearchAssetsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AdminSearchAssetsQuery, AdminSearchAssetsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AdminSearchAssetsQuery, AdminSearchAssetsQueryVariables>(AdminSearchAssetsDocument, options);
        }
export type AdminSearchAssetsQueryHookResult = ReturnType<typeof useAdminSearchAssetsQuery>;
export type AdminSearchAssetsLazyQueryHookResult = ReturnType<typeof useAdminSearchAssetsLazyQuery>;
export type AdminSearchAssetsQueryResult = Apollo.QueryResult<AdminSearchAssetsQuery, AdminSearchAssetsQueryVariables>;
export const GetApisDocument = gql`
    query GetApis {
  apiCatalog {
    apis {
      name
      plans {
        slug
      }
    }
  }
}
    `;

/**
 * __useGetApisQuery__
 *
 * To run a query within a React component, call `useGetApisQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetApisQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetApisQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetApisQuery(baseOptions?: Apollo.QueryHookOptions<GetApisQuery, GetApisQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetApisQuery, GetApisQueryVariables>(GetApisDocument, options);
      }
export function useGetApisLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetApisQuery, GetApisQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetApisQuery, GetApisQueryVariables>(GetApisDocument, options);
        }
export type GetApisQueryHookResult = ReturnType<typeof useGetApisQuery>;
export type GetApisLazyQueryHookResult = ReturnType<typeof useGetApisLazyQuery>;
export type GetApisQueryResult = Apollo.QueryResult<GetApisQuery, GetApisQueryVariables>;
export const GetApiSubscriptionsDocument = gql`
    query GetApiSubscriptions {
  apiCatalog {
    userSubscriptions {
      id
      createdAt
      api {
        name
      }
      plan {
        slug
      }
      keys {
        id
        key
        createdAt
        updatedAt
      }
    }
  }
}
    `;

/**
 * __useGetApiSubscriptionsQuery__
 *
 * To run a query within a React component, call `useGetApiSubscriptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetApiSubscriptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetApiSubscriptionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetApiSubscriptionsQuery(baseOptions?: Apollo.QueryHookOptions<GetApiSubscriptionsQuery, GetApiSubscriptionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetApiSubscriptionsQuery, GetApiSubscriptionsQueryVariables>(GetApiSubscriptionsDocument, options);
      }
export function useGetApiSubscriptionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetApiSubscriptionsQuery, GetApiSubscriptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetApiSubscriptionsQuery, GetApiSubscriptionsQueryVariables>(GetApiSubscriptionsDocument, options);
        }
export type GetApiSubscriptionsQueryHookResult = ReturnType<typeof useGetApiSubscriptionsQuery>;
export type GetApiSubscriptionsLazyQueryHookResult = ReturnType<typeof useGetApiSubscriptionsLazyQuery>;
export type GetApiSubscriptionsQueryResult = Apollo.QueryResult<GetApiSubscriptionsQuery, GetApiSubscriptionsQueryVariables>;
export const GetApiCatalogMeteringDocument = gql`
    query GetApiCatalogMetering($where: ApiCatalogMeteringWhereInput!) {
  cube(where: {api_catalog_metering: $where}) {
    api_catalog_metering(orderBy: {day: asc}) {
      sum_calls
      day {
        day
      }
    }
  }
}
    `;

/**
 * __useGetApiCatalogMeteringQuery__
 *
 * To run a query within a React component, call `useGetApiCatalogMeteringQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetApiCatalogMeteringQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetApiCatalogMeteringQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetApiCatalogMeteringQuery(baseOptions: Apollo.QueryHookOptions<GetApiCatalogMeteringQuery, GetApiCatalogMeteringQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetApiCatalogMeteringQuery, GetApiCatalogMeteringQueryVariables>(GetApiCatalogMeteringDocument, options);
      }
export function useGetApiCatalogMeteringLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetApiCatalogMeteringQuery, GetApiCatalogMeteringQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetApiCatalogMeteringQuery, GetApiCatalogMeteringQueryVariables>(GetApiCatalogMeteringDocument, options);
        }
export type GetApiCatalogMeteringQueryHookResult = ReturnType<typeof useGetApiCatalogMeteringQuery>;
export type GetApiCatalogMeteringLazyQueryHookResult = ReturnType<typeof useGetApiCatalogMeteringLazyQuery>;
export type GetApiCatalogMeteringQueryResult = Apollo.QueryResult<GetApiCatalogMeteringQuery, GetApiCatalogMeteringQueryVariables>;
export const CreateApiSubscriptionDocument = gql`
    mutation CreateApiSubscription($command: CreateUserApiSubscriptionCommand!) {
  apiCatalog {
    createUserSubscription(command: $command) {
      id
      createdAt
      api {
        name
      }
      plan {
        slug
      }
      keys {
        id
        key
        createdAt
        updatedAt
      }
    }
  }
}
    `;
export type CreateApiSubscriptionMutationFn = Apollo.MutationFunction<CreateApiSubscriptionMutation, CreateApiSubscriptionMutationVariables>;

/**
 * __useCreateApiSubscriptionMutation__
 *
 * To run a mutation, you first call `useCreateApiSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateApiSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createApiSubscriptionMutation, { data, loading, error }] = useCreateApiSubscriptionMutation({
 *   variables: {
 *      command: // value for 'command'
 *   },
 * });
 */
export function useCreateApiSubscriptionMutation(baseOptions?: Apollo.MutationHookOptions<CreateApiSubscriptionMutation, CreateApiSubscriptionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateApiSubscriptionMutation, CreateApiSubscriptionMutationVariables>(CreateApiSubscriptionDocument, options);
      }
export type CreateApiSubscriptionMutationHookResult = ReturnType<typeof useCreateApiSubscriptionMutation>;
export type CreateApiSubscriptionMutationResult = Apollo.MutationResult<CreateApiSubscriptionMutation>;
export type CreateApiSubscriptionMutationOptions = Apollo.BaseMutationOptions<CreateApiSubscriptionMutation, CreateApiSubscriptionMutationVariables>;
export const CreateApiSubscriptionKeyDocument = gql`
    mutation CreateApiSubscriptionKey($subscriptionId: ID!) {
  apiCatalog {
    createUserApiKey(subscriptionId: $subscriptionId) {
      id
      key
      createdAt
      updatedAt
    }
  }
}
    `;
export type CreateApiSubscriptionKeyMutationFn = Apollo.MutationFunction<CreateApiSubscriptionKeyMutation, CreateApiSubscriptionKeyMutationVariables>;

/**
 * __useCreateApiSubscriptionKeyMutation__
 *
 * To run a mutation, you first call `useCreateApiSubscriptionKeyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateApiSubscriptionKeyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createApiSubscriptionKeyMutation, { data, loading, error }] = useCreateApiSubscriptionKeyMutation({
 *   variables: {
 *      subscriptionId: // value for 'subscriptionId'
 *   },
 * });
 */
export function useCreateApiSubscriptionKeyMutation(baseOptions?: Apollo.MutationHookOptions<CreateApiSubscriptionKeyMutation, CreateApiSubscriptionKeyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateApiSubscriptionKeyMutation, CreateApiSubscriptionKeyMutationVariables>(CreateApiSubscriptionKeyDocument, options);
      }
export type CreateApiSubscriptionKeyMutationHookResult = ReturnType<typeof useCreateApiSubscriptionKeyMutation>;
export type CreateApiSubscriptionKeyMutationResult = Apollo.MutationResult<CreateApiSubscriptionKeyMutation>;
export type CreateApiSubscriptionKeyMutationOptions = Apollo.BaseMutationOptions<CreateApiSubscriptionKeyMutation, CreateApiSubscriptionKeyMutationVariables>;
export const DeleteApiSubscriptionKeyDocument = gql`
    mutation DeleteApiSubscriptionKey($id: ID!) {
  apiCatalog {
    deleteUserApiKey(id: $id)
  }
}
    `;
export type DeleteApiSubscriptionKeyMutationFn = Apollo.MutationFunction<DeleteApiSubscriptionKeyMutation, DeleteApiSubscriptionKeyMutationVariables>;

/**
 * __useDeleteApiSubscriptionKeyMutation__
 *
 * To run a mutation, you first call `useDeleteApiSubscriptionKeyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteApiSubscriptionKeyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteApiSubscriptionKeyMutation, { data, loading, error }] = useDeleteApiSubscriptionKeyMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteApiSubscriptionKeyMutation(baseOptions?: Apollo.MutationHookOptions<DeleteApiSubscriptionKeyMutation, DeleteApiSubscriptionKeyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteApiSubscriptionKeyMutation, DeleteApiSubscriptionKeyMutationVariables>(DeleteApiSubscriptionKeyDocument, options);
      }
export type DeleteApiSubscriptionKeyMutationHookResult = ReturnType<typeof useDeleteApiSubscriptionKeyMutation>;
export type DeleteApiSubscriptionKeyMutationResult = Apollo.MutationResult<DeleteApiSubscriptionKeyMutation>;
export type DeleteApiSubscriptionKeyMutationOptions = Apollo.BaseMutationOptions<DeleteApiSubscriptionKeyMutation, DeleteApiSubscriptionKeyMutationVariables>;
export const DeleteApiSubscriptionDocument = gql`
    mutation DeleteApiSubscription($id: ID!) {
  apiCatalog {
    deleteUserSubscription(id: $id)
  }
}
    `;
export type DeleteApiSubscriptionMutationFn = Apollo.MutationFunction<DeleteApiSubscriptionMutation, DeleteApiSubscriptionMutationVariables>;

/**
 * __useDeleteApiSubscriptionMutation__
 *
 * To run a mutation, you first call `useDeleteApiSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteApiSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteApiSubscriptionMutation, { data, loading, error }] = useDeleteApiSubscriptionMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteApiSubscriptionMutation(baseOptions?: Apollo.MutationHookOptions<DeleteApiSubscriptionMutation, DeleteApiSubscriptionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteApiSubscriptionMutation, DeleteApiSubscriptionMutationVariables>(DeleteApiSubscriptionDocument, options);
      }
export type DeleteApiSubscriptionMutationHookResult = ReturnType<typeof useDeleteApiSubscriptionMutation>;
export type DeleteApiSubscriptionMutationResult = Apollo.MutationResult<DeleteApiSubscriptionMutation>;
export type DeleteApiSubscriptionMutationOptions = Apollo.BaseMutationOptions<DeleteApiSubscriptionMutation, DeleteApiSubscriptionMutationVariables>;
export const GetHubspotContactListsDocument = gql`
    query GetHubspotContactLists {
  hubspot {
    contactLists {
      id
      name
      contactCount
    }
  }
}
    `;

/**
 * __useGetHubspotContactListsQuery__
 *
 * To run a query within a React component, call `useGetHubspotContactListsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetHubspotContactListsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetHubspotContactListsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetHubspotContactListsQuery(baseOptions?: Apollo.QueryHookOptions<GetHubspotContactListsQuery, GetHubspotContactListsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetHubspotContactListsQuery, GetHubspotContactListsQueryVariables>(GetHubspotContactListsDocument, options);
      }
export function useGetHubspotContactListsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetHubspotContactListsQuery, GetHubspotContactListsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetHubspotContactListsQuery, GetHubspotContactListsQueryVariables>(GetHubspotContactListsDocument, options);
        }
export type GetHubspotContactListsQueryHookResult = ReturnType<typeof useGetHubspotContactListsQuery>;
export type GetHubspotContactListsLazyQueryHookResult = ReturnType<typeof useGetHubspotContactListsLazyQuery>;
export type GetHubspotContactListsQueryResult = Apollo.QueryResult<GetHubspotContactListsQuery, GetHubspotContactListsQueryVariables>;
export const ImportHubspotListDocument = gql`
    mutation ImportHubspotList($command: ImportHubspotListCommand!) {
  hubspot {
    importList(command: $command) {
      importedUsers {
        id
        email
        firstname
        lastname
      }
      errorMessages
    }
  }
}
    `;
export type ImportHubspotListMutationFn = Apollo.MutationFunction<ImportHubspotListMutation, ImportHubspotListMutationVariables>;

/**
 * __useImportHubspotListMutation__
 *
 * To run a mutation, you first call `useImportHubspotListMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useImportHubspotListMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [importHubspotListMutation, { data, loading, error }] = useImportHubspotListMutation({
 *   variables: {
 *      command: // value for 'command'
 *   },
 * });
 */
export function useImportHubspotListMutation(baseOptions?: Apollo.MutationHookOptions<ImportHubspotListMutation, ImportHubspotListMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ImportHubspotListMutation, ImportHubspotListMutationVariables>(ImportHubspotListDocument, options);
      }
export type ImportHubspotListMutationHookResult = ReturnType<typeof useImportHubspotListMutation>;
export type ImportHubspotListMutationResult = Apollo.MutationResult<ImportHubspotListMutation>;
export type ImportHubspotListMutationOptions = Apollo.BaseMutationOptions<ImportHubspotListMutation, ImportHubspotListMutationVariables>;
export const SendVerificationEmailDocument = gql`
    mutation SendVerificationEmail($hubspotContactListId: ID!) {
  hubspot {
    sendVerificationEmails(hubspotContactListId: $hubspotContactListId)
  }
}
    `;
export type SendVerificationEmailMutationFn = Apollo.MutationFunction<SendVerificationEmailMutation, SendVerificationEmailMutationVariables>;

/**
 * __useSendVerificationEmailMutation__
 *
 * To run a mutation, you first call `useSendVerificationEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendVerificationEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendVerificationEmailMutation, { data, loading, error }] = useSendVerificationEmailMutation({
 *   variables: {
 *      hubspotContactListId: // value for 'hubspotContactListId'
 *   },
 * });
 */
export function useSendVerificationEmailMutation(baseOptions?: Apollo.MutationHookOptions<SendVerificationEmailMutation, SendVerificationEmailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendVerificationEmailMutation, SendVerificationEmailMutationVariables>(SendVerificationEmailDocument, options);
      }
export type SendVerificationEmailMutationHookResult = ReturnType<typeof useSendVerificationEmailMutation>;
export type SendVerificationEmailMutationResult = Apollo.MutationResult<SendVerificationEmailMutation>;
export type SendVerificationEmailMutationOptions = Apollo.BaseMutationOptions<SendVerificationEmailMutation, SendVerificationEmailMutationVariables>;
export const CreateSensorRegistrationTokensDocument = gql`
    mutation CreateSensorRegistrationTokens($command: CreateSensorRegistrationTokensCommand!) {
  hubspot {
    createSensorRegistrationTokens(command: $command) {
      createdTokensForUsers {
        id
        email
        firstname
        lastname
      }
      errorMessages
    }
  }
}
    `;
export type CreateSensorRegistrationTokensMutationFn = Apollo.MutationFunction<CreateSensorRegistrationTokensMutation, CreateSensorRegistrationTokensMutationVariables>;

/**
 * __useCreateSensorRegistrationTokensMutation__
 *
 * To run a mutation, you first call `useCreateSensorRegistrationTokensMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSensorRegistrationTokensMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSensorRegistrationTokensMutation, { data, loading, error }] = useCreateSensorRegistrationTokensMutation({
 *   variables: {
 *      command: // value for 'command'
 *   },
 * });
 */
export function useCreateSensorRegistrationTokensMutation(baseOptions?: Apollo.MutationHookOptions<CreateSensorRegistrationTokensMutation, CreateSensorRegistrationTokensMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateSensorRegistrationTokensMutation, CreateSensorRegistrationTokensMutationVariables>(CreateSensorRegistrationTokensDocument, options);
      }
export type CreateSensorRegistrationTokensMutationHookResult = ReturnType<typeof useCreateSensorRegistrationTokensMutation>;
export type CreateSensorRegistrationTokensMutationResult = Apollo.MutationResult<CreateSensorRegistrationTokensMutation>;
export type CreateSensorRegistrationTokensMutationOptions = Apollo.BaseMutationOptions<CreateSensorRegistrationTokensMutation, CreateSensorRegistrationTokensMutationVariables>;
export const GetOrganizationsDocument = gql`
    query GetOrganizations {
  organizations {
    id
    name
    orgId
  }
}
    `;

/**
 * __useGetOrganizationsQuery__
 *
 * To run a query within a React component, call `useGetOrganizationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrganizationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrganizationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetOrganizationsQuery(baseOptions?: Apollo.QueryHookOptions<GetOrganizationsQuery, GetOrganizationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOrganizationsQuery, GetOrganizationsQueryVariables>(GetOrganizationsDocument, options);
      }
export function useGetOrganizationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOrganizationsQuery, GetOrganizationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOrganizationsQuery, GetOrganizationsQueryVariables>(GetOrganizationsDocument, options);
        }
export type GetOrganizationsQueryHookResult = ReturnType<typeof useGetOrganizationsQuery>;
export type GetOrganizationsLazyQueryHookResult = ReturnType<typeof useGetOrganizationsLazyQuery>;
export type GetOrganizationsQueryResult = Apollo.QueryResult<GetOrganizationsQuery, GetOrganizationsQueryVariables>;
export const GetOrganizationWithUsersDocument = gql`
    query GetOrganizationWithUsers($id: ID!) {
  organization(id: $id) {
    id
    name
    orgId
    users {
      user {
        id
        createdAt
        updatedAt
        firstname
        lastname
        email
      }
    }
  }
}
    `;

/**
 * __useGetOrganizationWithUsersQuery__
 *
 * To run a query within a React component, call `useGetOrganizationWithUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrganizationWithUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrganizationWithUsersQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetOrganizationWithUsersQuery(baseOptions: Apollo.QueryHookOptions<GetOrganizationWithUsersQuery, GetOrganizationWithUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOrganizationWithUsersQuery, GetOrganizationWithUsersQueryVariables>(GetOrganizationWithUsersDocument, options);
      }
export function useGetOrganizationWithUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOrganizationWithUsersQuery, GetOrganizationWithUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOrganizationWithUsersQuery, GetOrganizationWithUsersQueryVariables>(GetOrganizationWithUsersDocument, options);
        }
export type GetOrganizationWithUsersQueryHookResult = ReturnType<typeof useGetOrganizationWithUsersQuery>;
export type GetOrganizationWithUsersLazyQueryHookResult = ReturnType<typeof useGetOrganizationWithUsersLazyQuery>;
export type GetOrganizationWithUsersQueryResult = Apollo.QueryResult<GetOrganizationWithUsersQuery, GetOrganizationWithUsersQueryVariables>;
export const CreateOrganizationDocument = gql`
    mutation CreateOrganization($command: CreateOrganizationCommand!) {
  organizations {
    createOrganization(command: $command) {
      id
      name
      orgId
    }
  }
}
    `;
export type CreateOrganizationMutationFn = Apollo.MutationFunction<CreateOrganizationMutation, CreateOrganizationMutationVariables>;

/**
 * __useCreateOrganizationMutation__
 *
 * To run a mutation, you first call `useCreateOrganizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrganizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrganizationMutation, { data, loading, error }] = useCreateOrganizationMutation({
 *   variables: {
 *      command: // value for 'command'
 *   },
 * });
 */
export function useCreateOrganizationMutation(baseOptions?: Apollo.MutationHookOptions<CreateOrganizationMutation, CreateOrganizationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateOrganizationMutation, CreateOrganizationMutationVariables>(CreateOrganizationDocument, options);
      }
export type CreateOrganizationMutationHookResult = ReturnType<typeof useCreateOrganizationMutation>;
export type CreateOrganizationMutationResult = Apollo.MutationResult<CreateOrganizationMutation>;
export type CreateOrganizationMutationOptions = Apollo.BaseMutationOptions<CreateOrganizationMutation, CreateOrganizationMutationVariables>;
export const CreateGroupDocument = gql`
    mutation CreateGroup($command: CreateGroupCommand!) {
  adminCreateGroup(input: $command) @rest(type: "GroupResult", path: "/groups", method: "POST") {
    data {
      id
      name
    }
  }
}
    `;
export type CreateGroupMutationFn = Apollo.MutationFunction<CreateGroupMutation, CreateGroupMutationVariables>;

/**
 * __useCreateGroupMutation__
 *
 * To run a mutation, you first call `useCreateGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createGroupMutation, { data, loading, error }] = useCreateGroupMutation({
 *   variables: {
 *      command: // value for 'command'
 *   },
 * });
 */
export function useCreateGroupMutation(baseOptions?: Apollo.MutationHookOptions<CreateGroupMutation, CreateGroupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateGroupMutation, CreateGroupMutationVariables>(CreateGroupDocument, options);
      }
export type CreateGroupMutationHookResult = ReturnType<typeof useCreateGroupMutation>;
export type CreateGroupMutationResult = Apollo.MutationResult<CreateGroupMutation>;
export type CreateGroupMutationOptions = Apollo.BaseMutationOptions<CreateGroupMutation, CreateGroupMutationVariables>;
export const GetRegisteredSensorSchemeDocument = gql`
    query GetRegisteredSensorScheme {
  scheme @rest(type: "SchemeResult", path: "/registered-sensor/scheme", method: "GET") {
    data {
      name
      propertyTypes {
        name
        validation
        description
      }
    }
  }
}
    `;

/**
 * __useGetRegisteredSensorSchemeQuery__
 *
 * To run a query within a React component, call `useGetRegisteredSensorSchemeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRegisteredSensorSchemeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRegisteredSensorSchemeQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetRegisteredSensorSchemeQuery(baseOptions?: Apollo.QueryHookOptions<GetRegisteredSensorSchemeQuery, GetRegisteredSensorSchemeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetRegisteredSensorSchemeQuery, GetRegisteredSensorSchemeQueryVariables>(GetRegisteredSensorSchemeDocument, options);
      }
export function useGetRegisteredSensorSchemeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRegisteredSensorSchemeQuery, GetRegisteredSensorSchemeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetRegisteredSensorSchemeQuery, GetRegisteredSensorSchemeQueryVariables>(GetRegisteredSensorSchemeDocument, options);
        }
export type GetRegisteredSensorSchemeQueryHookResult = ReturnType<typeof useGetRegisteredSensorSchemeQuery>;
export type GetRegisteredSensorSchemeLazyQueryHookResult = ReturnType<typeof useGetRegisteredSensorSchemeLazyQuery>;
export type GetRegisteredSensorSchemeQueryResult = Apollo.QueryResult<GetRegisteredSensorSchemeQuery, GetRegisteredSensorSchemeQueryVariables>;
export const PaginateRegisteredSensorAssetsDocument = gql`
    query PaginateRegisteredSensorAssets($filterQuery: String) {
  registeredSensorAssets(filterQuery: $filterQuery) @rest(type: "AssetsResult", path: "/registered-sensor/assets{args.filterQuery}", method: "GET") {
    data {
      id
      name
      createdAt
      updatedAt
      properties {
        id
        key
        value
        type
      }
    }
    meta {
      currentPage
      lastPage
      perPage
      from
      to
      total
      links {
        url
        active
        label
      }
    }
  }
}
    `;

/**
 * __usePaginateRegisteredSensorAssetsQuery__
 *
 * To run a query within a React component, call `usePaginateRegisteredSensorAssetsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaginateRegisteredSensorAssetsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaginateRegisteredSensorAssetsQuery({
 *   variables: {
 *      filterQuery: // value for 'filterQuery'
 *   },
 * });
 */
export function usePaginateRegisteredSensorAssetsQuery(baseOptions?: Apollo.QueryHookOptions<PaginateRegisteredSensorAssetsQuery, PaginateRegisteredSensorAssetsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PaginateRegisteredSensorAssetsQuery, PaginateRegisteredSensorAssetsQueryVariables>(PaginateRegisteredSensorAssetsDocument, options);
      }
export function usePaginateRegisteredSensorAssetsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PaginateRegisteredSensorAssetsQuery, PaginateRegisteredSensorAssetsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PaginateRegisteredSensorAssetsQuery, PaginateRegisteredSensorAssetsQueryVariables>(PaginateRegisteredSensorAssetsDocument, options);
        }
export type PaginateRegisteredSensorAssetsQueryHookResult = ReturnType<typeof usePaginateRegisteredSensorAssetsQuery>;
export type PaginateRegisteredSensorAssetsLazyQueryHookResult = ReturnType<typeof usePaginateRegisteredSensorAssetsLazyQuery>;
export type PaginateRegisteredSensorAssetsQueryResult = Apollo.QueryResult<PaginateRegisteredSensorAssetsQuery, PaginateRegisteredSensorAssetsQueryVariables>;
export const SearchRegisteredSensorAssetsDocument = gql`
    query SearchRegisteredSensorAssets($searchQuery: String) {
  searchRegisteredSensorAssets(searchQuery: $searchQuery) @rest(type: "AssetsResult", path: "/registered-sensor/assets/search{args.searchQuery}", method: "GET") {
    data {
      id
      name
      createdAt
      updatedAt
      properties {
        id
        key
        value
        type
      }
    }
    meta {
      currentPage
      lastPage
      perPage
      from
      to
      total
      links {
        url
        active
        label
      }
    }
  }
}
    `;

/**
 * __useSearchRegisteredSensorAssetsQuery__
 *
 * To run a query within a React component, call `useSearchRegisteredSensorAssetsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchRegisteredSensorAssetsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchRegisteredSensorAssetsQuery({
 *   variables: {
 *      searchQuery: // value for 'searchQuery'
 *   },
 * });
 */
export function useSearchRegisteredSensorAssetsQuery(baseOptions?: Apollo.QueryHookOptions<SearchRegisteredSensorAssetsQuery, SearchRegisteredSensorAssetsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchRegisteredSensorAssetsQuery, SearchRegisteredSensorAssetsQueryVariables>(SearchRegisteredSensorAssetsDocument, options);
      }
export function useSearchRegisteredSensorAssetsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchRegisteredSensorAssetsQuery, SearchRegisteredSensorAssetsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchRegisteredSensorAssetsQuery, SearchRegisteredSensorAssetsQueryVariables>(SearchRegisteredSensorAssetsDocument, options);
        }
export type SearchRegisteredSensorAssetsQueryHookResult = ReturnType<typeof useSearchRegisteredSensorAssetsQuery>;
export type SearchRegisteredSensorAssetsLazyQueryHookResult = ReturnType<typeof useSearchRegisteredSensorAssetsLazyQuery>;
export type SearchRegisteredSensorAssetsQueryResult = Apollo.QueryResult<SearchRegisteredSensorAssetsQuery, SearchRegisteredSensorAssetsQueryVariables>;
export const GetRegisteredSensorAssetDocument = gql`
    query GetRegisteredSensorAsset($id: ID!) {
  registeredSensorAsset(id: $id) @rest(type: "AssetResult", path: "/registered-sensor/assets/{args.id}", method: "GET") {
    data {
      id
      name
      createdAt
      updatedAt
      properties {
        id
        key
        value
        type
      }
    }
  }
}
    `;

/**
 * __useGetRegisteredSensorAssetQuery__
 *
 * To run a query within a React component, call `useGetRegisteredSensorAssetQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRegisteredSensorAssetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRegisteredSensorAssetQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetRegisteredSensorAssetQuery(baseOptions: Apollo.QueryHookOptions<GetRegisteredSensorAssetQuery, GetRegisteredSensorAssetQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetRegisteredSensorAssetQuery, GetRegisteredSensorAssetQueryVariables>(GetRegisteredSensorAssetDocument, options);
      }
export function useGetRegisteredSensorAssetLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRegisteredSensorAssetQuery, GetRegisteredSensorAssetQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetRegisteredSensorAssetQuery, GetRegisteredSensorAssetQueryVariables>(GetRegisteredSensorAssetDocument, options);
        }
export type GetRegisteredSensorAssetQueryHookResult = ReturnType<typeof useGetRegisteredSensorAssetQuery>;
export type GetRegisteredSensorAssetLazyQueryHookResult = ReturnType<typeof useGetRegisteredSensorAssetLazyQuery>;
export type GetRegisteredSensorAssetQueryResult = Apollo.QueryResult<GetRegisteredSensorAssetQuery, GetRegisteredSensorAssetQueryVariables>;
export const CreateRegisteredSensorAssetDocument = gql`
    mutation CreateRegisteredSensorAsset($command: CreateAssetCommand!) {
  createRegisteredSensorAsset(input: $command) @rest(type: "AssetResult", path: "/registered-sensor/assets", method: "POST") {
    data {
      id
      name
      createdAt
    }
  }
}
    `;
export type CreateRegisteredSensorAssetMutationFn = Apollo.MutationFunction<CreateRegisteredSensorAssetMutation, CreateRegisteredSensorAssetMutationVariables>;

/**
 * __useCreateRegisteredSensorAssetMutation__
 *
 * To run a mutation, you first call `useCreateRegisteredSensorAssetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRegisteredSensorAssetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRegisteredSensorAssetMutation, { data, loading, error }] = useCreateRegisteredSensorAssetMutation({
 *   variables: {
 *      command: // value for 'command'
 *   },
 * });
 */
export function useCreateRegisteredSensorAssetMutation(baseOptions?: Apollo.MutationHookOptions<CreateRegisteredSensorAssetMutation, CreateRegisteredSensorAssetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateRegisteredSensorAssetMutation, CreateRegisteredSensorAssetMutationVariables>(CreateRegisteredSensorAssetDocument, options);
      }
export type CreateRegisteredSensorAssetMutationHookResult = ReturnType<typeof useCreateRegisteredSensorAssetMutation>;
export type CreateRegisteredSensorAssetMutationResult = Apollo.MutationResult<CreateRegisteredSensorAssetMutation>;
export type CreateRegisteredSensorAssetMutationOptions = Apollo.BaseMutationOptions<CreateRegisteredSensorAssetMutation, CreateRegisteredSensorAssetMutationVariables>;
export const BulkCreateRegisteredSensorAssetDocument = gql`
    mutation BulkCreateRegisteredSensorAsset($command: [CreateAssetCommand]!) {
  bulkCreateRegisteredSensorAssets(input: $command) @rest(type: "AssetsResult", path: "/registered-sensor/assets/bulk", method: "POST") {
    data {
      id
      name
      createdAt
    }
  }
}
    `;
export type BulkCreateRegisteredSensorAssetMutationFn = Apollo.MutationFunction<BulkCreateRegisteredSensorAssetMutation, BulkCreateRegisteredSensorAssetMutationVariables>;

/**
 * __useBulkCreateRegisteredSensorAssetMutation__
 *
 * To run a mutation, you first call `useBulkCreateRegisteredSensorAssetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBulkCreateRegisteredSensorAssetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bulkCreateRegisteredSensorAssetMutation, { data, loading, error }] = useBulkCreateRegisteredSensorAssetMutation({
 *   variables: {
 *      command: // value for 'command'
 *   },
 * });
 */
export function useBulkCreateRegisteredSensorAssetMutation(baseOptions?: Apollo.MutationHookOptions<BulkCreateRegisteredSensorAssetMutation, BulkCreateRegisteredSensorAssetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<BulkCreateRegisteredSensorAssetMutation, BulkCreateRegisteredSensorAssetMutationVariables>(BulkCreateRegisteredSensorAssetDocument, options);
      }
export type BulkCreateRegisteredSensorAssetMutationHookResult = ReturnType<typeof useBulkCreateRegisteredSensorAssetMutation>;
export type BulkCreateRegisteredSensorAssetMutationResult = Apollo.MutationResult<BulkCreateRegisteredSensorAssetMutation>;
export type BulkCreateRegisteredSensorAssetMutationOptions = Apollo.BaseMutationOptions<BulkCreateRegisteredSensorAssetMutation, BulkCreateRegisteredSensorAssetMutationVariables>;
export const UpdateRegisteredSensorAssetDocument = gql`
    mutation UpdateRegisteredSensorAsset($command: UpdateAssetCommand!) {
  updateRegisteredSensorAsset(input: $command) @rest(type: "AssetResult", path: "/registered-sensor/assets/{args.input.id}", method: "PATCH") {
    data {
      id
      name
      properties {
        id
        key
        value
        type
      }
    }
  }
}
    `;
export type UpdateRegisteredSensorAssetMutationFn = Apollo.MutationFunction<UpdateRegisteredSensorAssetMutation, UpdateRegisteredSensorAssetMutationVariables>;

/**
 * __useUpdateRegisteredSensorAssetMutation__
 *
 * To run a mutation, you first call `useUpdateRegisteredSensorAssetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRegisteredSensorAssetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRegisteredSensorAssetMutation, { data, loading, error }] = useUpdateRegisteredSensorAssetMutation({
 *   variables: {
 *      command: // value for 'command'
 *   },
 * });
 */
export function useUpdateRegisteredSensorAssetMutation(baseOptions?: Apollo.MutationHookOptions<UpdateRegisteredSensorAssetMutation, UpdateRegisteredSensorAssetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateRegisteredSensorAssetMutation, UpdateRegisteredSensorAssetMutationVariables>(UpdateRegisteredSensorAssetDocument, options);
      }
export type UpdateRegisteredSensorAssetMutationHookResult = ReturnType<typeof useUpdateRegisteredSensorAssetMutation>;
export type UpdateRegisteredSensorAssetMutationResult = Apollo.MutationResult<UpdateRegisteredSensorAssetMutation>;
export type UpdateRegisteredSensorAssetMutationOptions = Apollo.BaseMutationOptions<UpdateRegisteredSensorAssetMutation, UpdateRegisteredSensorAssetMutationVariables>;
export const DeleteRegisteredSensorAssetDocument = gql`
    mutation DeleteRegisteredSensorAsset($assetId: ID!) {
  deleteRegisteredSensorAsset(assetId: $assetId) @rest(type: "AssetResult", path: "/registered-sensor/assets/{args.assetId}", method: "DELETE") {
    data {
      id
    }
  }
}
    `;
export type DeleteRegisteredSensorAssetMutationFn = Apollo.MutationFunction<DeleteRegisteredSensorAssetMutation, DeleteRegisteredSensorAssetMutationVariables>;

/**
 * __useDeleteRegisteredSensorAssetMutation__
 *
 * To run a mutation, you first call `useDeleteRegisteredSensorAssetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteRegisteredSensorAssetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteRegisteredSensorAssetMutation, { data, loading, error }] = useDeleteRegisteredSensorAssetMutation({
 *   variables: {
 *      assetId: // value for 'assetId'
 *   },
 * });
 */
export function useDeleteRegisteredSensorAssetMutation(baseOptions?: Apollo.MutationHookOptions<DeleteRegisteredSensorAssetMutation, DeleteRegisteredSensorAssetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteRegisteredSensorAssetMutation, DeleteRegisteredSensorAssetMutationVariables>(DeleteRegisteredSensorAssetDocument, options);
      }
export type DeleteRegisteredSensorAssetMutationHookResult = ReturnType<typeof useDeleteRegisteredSensorAssetMutation>;
export type DeleteRegisteredSensorAssetMutationResult = Apollo.MutationResult<DeleteRegisteredSensorAssetMutation>;
export type DeleteRegisteredSensorAssetMutationOptions = Apollo.BaseMutationOptions<DeleteRegisteredSensorAssetMutation, DeleteRegisteredSensorAssetMutationVariables>;
export const UpdateRegisteredSensorPropertyDocument = gql`
    mutation UpdateRegisteredSensorProperty($command: UpdatePropertyCommand!) {
  updateRegisteredSensorProperty(input: $command) @rest(type: "PropertyResult", path: "/registered-sensor/assets/{args.input.assetId}/properties/{args.input.propertyId}", method: "PUT") {
    data {
      id
      key
      type
      value
    }
  }
}
    `;
export type UpdateRegisteredSensorPropertyMutationFn = Apollo.MutationFunction<UpdateRegisteredSensorPropertyMutation, UpdateRegisteredSensorPropertyMutationVariables>;

/**
 * __useUpdateRegisteredSensorPropertyMutation__
 *
 * To run a mutation, you first call `useUpdateRegisteredSensorPropertyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRegisteredSensorPropertyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRegisteredSensorPropertyMutation, { data, loading, error }] = useUpdateRegisteredSensorPropertyMutation({
 *   variables: {
 *      command: // value for 'command'
 *   },
 * });
 */
export function useUpdateRegisteredSensorPropertyMutation(baseOptions?: Apollo.MutationHookOptions<UpdateRegisteredSensorPropertyMutation, UpdateRegisteredSensorPropertyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateRegisteredSensorPropertyMutation, UpdateRegisteredSensorPropertyMutationVariables>(UpdateRegisteredSensorPropertyDocument, options);
      }
export type UpdateRegisteredSensorPropertyMutationHookResult = ReturnType<typeof useUpdateRegisteredSensorPropertyMutation>;
export type UpdateRegisteredSensorPropertyMutationResult = Apollo.MutationResult<UpdateRegisteredSensorPropertyMutation>;
export type UpdateRegisteredSensorPropertyMutationOptions = Apollo.BaseMutationOptions<UpdateRegisteredSensorPropertyMutation, UpdateRegisteredSensorPropertyMutationVariables>;
export const DeleteRegisteredSensorPropertyDocument = gql`
    mutation DeleteRegisteredSensorProperty($command: DeletePropertyCommand!) {
  deleteRegisteredSensorProperty(input: $command) @rest(type: "PropertyResult", path: "/registered-sensor/assets/{args.input.assetId}/properties/{args.input.propertyId}", method: "DELETE") {
    data {
      id
    }
  }
}
    `;
export type DeleteRegisteredSensorPropertyMutationFn = Apollo.MutationFunction<DeleteRegisteredSensorPropertyMutation, DeleteRegisteredSensorPropertyMutationVariables>;

/**
 * __useDeleteRegisteredSensorPropertyMutation__
 *
 * To run a mutation, you first call `useDeleteRegisteredSensorPropertyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteRegisteredSensorPropertyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteRegisteredSensorPropertyMutation, { data, loading, error }] = useDeleteRegisteredSensorPropertyMutation({
 *   variables: {
 *      command: // value for 'command'
 *   },
 * });
 */
export function useDeleteRegisteredSensorPropertyMutation(baseOptions?: Apollo.MutationHookOptions<DeleteRegisteredSensorPropertyMutation, DeleteRegisteredSensorPropertyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteRegisteredSensorPropertyMutation, DeleteRegisteredSensorPropertyMutationVariables>(DeleteRegisteredSensorPropertyDocument, options);
      }
export type DeleteRegisteredSensorPropertyMutationHookResult = ReturnType<typeof useDeleteRegisteredSensorPropertyMutation>;
export type DeleteRegisteredSensorPropertyMutationResult = Apollo.MutationResult<DeleteRegisteredSensorPropertyMutation>;
export type DeleteRegisteredSensorPropertyMutationOptions = Apollo.BaseMutationOptions<DeleteRegisteredSensorPropertyMutation, DeleteRegisteredSensorPropertyMutationVariables>;
export const GetPlatformAlertsDocument = gql`
    query GetPlatformAlerts {
  platformAlerts {
    id
    title
    message
  }
}
    `;

/**
 * __useGetPlatformAlertsQuery__
 *
 * To run a query within a React component, call `useGetPlatformAlertsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPlatformAlertsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPlatformAlertsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPlatformAlertsQuery(baseOptions?: Apollo.QueryHookOptions<GetPlatformAlertsQuery, GetPlatformAlertsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPlatformAlertsQuery, GetPlatformAlertsQueryVariables>(GetPlatformAlertsDocument, options);
      }
export function useGetPlatformAlertsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPlatformAlertsQuery, GetPlatformAlertsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPlatformAlertsQuery, GetPlatformAlertsQueryVariables>(GetPlatformAlertsDocument, options);
        }
export type GetPlatformAlertsQueryHookResult = ReturnType<typeof useGetPlatformAlertsQuery>;
export type GetPlatformAlertsLazyQueryHookResult = ReturnType<typeof useGetPlatformAlertsLazyQuery>;
export type GetPlatformAlertsQueryResult = Apollo.QueryResult<GetPlatformAlertsQuery, GetPlatformAlertsQueryVariables>;
export const SearchTreesDocument = gql`
    query SearchTrees($query: TreeSearchQuery!) {
  trees {
    treeSearch(query: $query) {
      ...TreeParts
    }
  }
}
    ${TreePartsFragmentDoc}`;

/**
 * __useSearchTreesQuery__
 *
 * To run a query within a React component, call `useSearchTreesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchTreesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchTreesQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useSearchTreesQuery(baseOptions: Apollo.QueryHookOptions<SearchTreesQuery, SearchTreesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchTreesQuery, SearchTreesQueryVariables>(SearchTreesDocument, options);
      }
export function useSearchTreesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchTreesQuery, SearchTreesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchTreesQuery, SearchTreesQueryVariables>(SearchTreesDocument, options);
        }
export type SearchTreesQueryHookResult = ReturnType<typeof useSearchTreesQuery>;
export type SearchTreesLazyQueryHookResult = ReturnType<typeof useSearchTreesLazyQuery>;
export type SearchTreesQueryResult = Apollo.QueryResult<SearchTreesQuery, SearchTreesQueryVariables>;
export const GetTreeSpeciesDocument = gql`
    query GetTreeSpecies {
  trees {
    treeSpecies {
      name
      code
      scientificName
    }
  }
}
    `;

/**
 * __useGetTreeSpeciesQuery__
 *
 * To run a query within a React component, call `useGetTreeSpeciesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTreeSpeciesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTreeSpeciesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetTreeSpeciesQuery(baseOptions?: Apollo.QueryHookOptions<GetTreeSpeciesQuery, GetTreeSpeciesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTreeSpeciesQuery, GetTreeSpeciesQueryVariables>(GetTreeSpeciesDocument, options);
      }
export function useGetTreeSpeciesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTreeSpeciesQuery, GetTreeSpeciesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTreeSpeciesQuery, GetTreeSpeciesQueryVariables>(GetTreeSpeciesDocument, options);
        }
export type GetTreeSpeciesQueryHookResult = ReturnType<typeof useGetTreeSpeciesQuery>;
export type GetTreeSpeciesLazyQueryHookResult = ReturnType<typeof useGetTreeSpeciesLazyQuery>;
export type GetTreeSpeciesQueryResult = Apollo.QueryResult<GetTreeSpeciesQuery, GetTreeSpeciesQueryVariables>;
export const CreateTreeDocument = gql`
    mutation CreateTree($command: CreateTreeCommand!) {
  trees {
    createTree(command: $command) {
      ...TreeParts
    }
  }
}
    ${TreePartsFragmentDoc}`;
export type CreateTreeMutationFn = Apollo.MutationFunction<CreateTreeMutation, CreateTreeMutationVariables>;

/**
 * __useCreateTreeMutation__
 *
 * To run a mutation, you first call `useCreateTreeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTreeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTreeMutation, { data, loading, error }] = useCreateTreeMutation({
 *   variables: {
 *      command: // value for 'command'
 *   },
 * });
 */
export function useCreateTreeMutation(baseOptions?: Apollo.MutationHookOptions<CreateTreeMutation, CreateTreeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateTreeMutation, CreateTreeMutationVariables>(CreateTreeDocument, options);
      }
export type CreateTreeMutationHookResult = ReturnType<typeof useCreateTreeMutation>;
export type CreateTreeMutationResult = Apollo.MutationResult<CreateTreeMutation>;
export type CreateTreeMutationOptions = Apollo.BaseMutationOptions<CreateTreeMutation, CreateTreeMutationVariables>;
export const UpdateTreeDocument = gql`
    mutation UpdateTree($command: UpdateTreeCommand!) {
  trees {
    updateTree(command: $command) {
      ...TreeParts
    }
  }
}
    ${TreePartsFragmentDoc}`;
export type UpdateTreeMutationFn = Apollo.MutationFunction<UpdateTreeMutation, UpdateTreeMutationVariables>;

/**
 * __useUpdateTreeMutation__
 *
 * To run a mutation, you first call `useUpdateTreeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTreeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTreeMutation, { data, loading, error }] = useUpdateTreeMutation({
 *   variables: {
 *      command: // value for 'command'
 *   },
 * });
 */
export function useUpdateTreeMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTreeMutation, UpdateTreeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateTreeMutation, UpdateTreeMutationVariables>(UpdateTreeDocument, options);
      }
export type UpdateTreeMutationHookResult = ReturnType<typeof useUpdateTreeMutation>;
export type UpdateTreeMutationResult = Apollo.MutationResult<UpdateTreeMutation>;
export type UpdateTreeMutationOptions = Apollo.BaseMutationOptions<UpdateTreeMutation, UpdateTreeMutationVariables>;
export const DeleteTreeDocument = gql`
    mutation DeleteTree($command: DeleteTreeCommand!) {
  trees {
    deleteTree(command: $command)
  }
}
    `;
export type DeleteTreeMutationFn = Apollo.MutationFunction<DeleteTreeMutation, DeleteTreeMutationVariables>;

/**
 * __useDeleteTreeMutation__
 *
 * To run a mutation, you first call `useDeleteTreeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTreeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTreeMutation, { data, loading, error }] = useDeleteTreeMutation({
 *   variables: {
 *      command: // value for 'command'
 *   },
 * });
 */
export function useDeleteTreeMutation(baseOptions?: Apollo.MutationHookOptions<DeleteTreeMutation, DeleteTreeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteTreeMutation, DeleteTreeMutationVariables>(DeleteTreeDocument, options);
      }
export type DeleteTreeMutationHookResult = ReturnType<typeof useDeleteTreeMutation>;
export type DeleteTreeMutationResult = Apollo.MutationResult<DeleteTreeMutation>;
export type DeleteTreeMutationOptions = Apollo.BaseMutationOptions<DeleteTreeMutation, DeleteTreeMutationVariables>;
export const GetSensorDataDocument = gql`
    query GetSensorData($id: ID!, $query: SensoterraQuery!) {
  sensoterraSensor(id: $id) {
    id
    state
    status {
      battery
      updatedAt
    }
    measurements(query: $query) {
      __typename
      humidity {
        ... on HumidityMeasurement {
          value
          dateTime
        }
        ... on SensorError {
          type
        }
      }
      temperature {
        ... on TemperatureMeasurement {
          value
          dateTime
          unit
        }
        ... on SensorError {
          type
        }
      }
    }
  }
}
    `;

/**
 * __useGetSensorDataQuery__
 *
 * To run a query within a React component, call `useGetSensorDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSensorDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSensorDataQuery({
 *   variables: {
 *      id: // value for 'id'
 *      query: // value for 'query'
 *   },
 * });
 */
export function useGetSensorDataQuery(baseOptions: Apollo.QueryHookOptions<GetSensorDataQuery, GetSensorDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSensorDataQuery, GetSensorDataQueryVariables>(GetSensorDataDocument, options);
      }
export function useGetSensorDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSensorDataQuery, GetSensorDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSensorDataQuery, GetSensorDataQueryVariables>(GetSensorDataDocument, options);
        }
export type GetSensorDataQueryHookResult = ReturnType<typeof useGetSensorDataQuery>;
export type GetSensorDataLazyQueryHookResult = ReturnType<typeof useGetSensorDataLazyQuery>;
export type GetSensorDataQueryResult = Apollo.QueryResult<GetSensorDataQuery, GetSensorDataQueryVariables>;
export const GetDailySensorDataCubeQueryDocument = gql`
    query GetDailySensorDataCubeQuery($where: PientereTuinenMeasurementsWhereInput!) {
  cube(limit: 365, where: {pientere_tuinen_measurements: $where}) {
    pientere_tuinen_measurements(orderBy: {measured_at: asc}) {
      user_id
      avg_temperature_celsius
      avg_moisture_percentage
      measured_at {
        day
      }
    }
  }
}
    `;

/**
 * __useGetDailySensorDataCubeQueryQuery__
 *
 * To run a query within a React component, call `useGetDailySensorDataCubeQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDailySensorDataCubeQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDailySensorDataCubeQueryQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetDailySensorDataCubeQueryQuery(baseOptions: Apollo.QueryHookOptions<GetDailySensorDataCubeQueryQuery, GetDailySensorDataCubeQueryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDailySensorDataCubeQueryQuery, GetDailySensorDataCubeQueryQueryVariables>(GetDailySensorDataCubeQueryDocument, options);
      }
export function useGetDailySensorDataCubeQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDailySensorDataCubeQueryQuery, GetDailySensorDataCubeQueryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDailySensorDataCubeQueryQuery, GetDailySensorDataCubeQueryQueryVariables>(GetDailySensorDataCubeQueryDocument, options);
        }
export type GetDailySensorDataCubeQueryQueryHookResult = ReturnType<typeof useGetDailySensorDataCubeQueryQuery>;
export type GetDailySensorDataCubeQueryLazyQueryHookResult = ReturnType<typeof useGetDailySensorDataCubeQueryLazyQuery>;
export type GetDailySensorDataCubeQueryQueryResult = Apollo.QueryResult<GetDailySensorDataCubeQueryQuery, GetDailySensorDataCubeQueryQueryVariables>;
export const GetHourlySensorDataCubeQueryDocument = gql`
    query GetHourlySensorDataCubeQuery($where: PientereTuinenMeasurementsWhereInput!) {
  cube(where: {pientere_tuinen_measurements: $where}) {
    pientere_tuinen_measurements(orderBy: {measured_at: asc}) {
      user_id
      avg_temperature_celsius
      avg_moisture_percentage
      measured_at {
        hour
      }
    }
  }
}
    `;

/**
 * __useGetHourlySensorDataCubeQueryQuery__
 *
 * To run a query within a React component, call `useGetHourlySensorDataCubeQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetHourlySensorDataCubeQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetHourlySensorDataCubeQueryQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetHourlySensorDataCubeQueryQuery(baseOptions: Apollo.QueryHookOptions<GetHourlySensorDataCubeQueryQuery, GetHourlySensorDataCubeQueryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetHourlySensorDataCubeQueryQuery, GetHourlySensorDataCubeQueryQueryVariables>(GetHourlySensorDataCubeQueryDocument, options);
      }
export function useGetHourlySensorDataCubeQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetHourlySensorDataCubeQueryQuery, GetHourlySensorDataCubeQueryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetHourlySensorDataCubeQueryQuery, GetHourlySensorDataCubeQueryQueryVariables>(GetHourlySensorDataCubeQueryDocument, options);
        }
export type GetHourlySensorDataCubeQueryQueryHookResult = ReturnType<typeof useGetHourlySensorDataCubeQueryQuery>;
export type GetHourlySensorDataCubeQueryLazyQueryHookResult = ReturnType<typeof useGetHourlySensorDataCubeQueryLazyQuery>;
export type GetHourlySensorDataCubeQueryQueryResult = Apollo.QueryResult<GetHourlySensorDataCubeQueryQuery, GetHourlySensorDataCubeQueryQueryVariables>;
export const MySensorRegistrationTokenDocument = gql`
    query MySensorRegistrationToken {
  mySensorRegistrationToken {
    user {
      id
      email
    }
    organization {
      id
      orgId
    }
  }
}
    `;

/**
 * __useMySensorRegistrationTokenQuery__
 *
 * To run a query within a React component, call `useMySensorRegistrationTokenQuery` and pass it any options that fit your needs.
 * When your component renders, `useMySensorRegistrationTokenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMySensorRegistrationTokenQuery({
 *   variables: {
 *   },
 * });
 */
export function useMySensorRegistrationTokenQuery(baseOptions?: Apollo.QueryHookOptions<MySensorRegistrationTokenQuery, MySensorRegistrationTokenQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MySensorRegistrationTokenQuery, MySensorRegistrationTokenQueryVariables>(MySensorRegistrationTokenDocument, options);
      }
export function useMySensorRegistrationTokenLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MySensorRegistrationTokenQuery, MySensorRegistrationTokenQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MySensorRegistrationTokenQuery, MySensorRegistrationTokenQueryVariables>(MySensorRegistrationTokenDocument, options);
        }
export type MySensorRegistrationTokenQueryHookResult = ReturnType<typeof useMySensorRegistrationTokenQuery>;
export type MySensorRegistrationTokenLazyQueryHookResult = ReturnType<typeof useMySensorRegistrationTokenLazyQuery>;
export type MySensorRegistrationTokenQueryResult = Apollo.QueryResult<MySensorRegistrationTokenQuery, MySensorRegistrationTokenQueryVariables>;
export const DeleteMySensorRegistrationTokenDocument = gql`
    mutation DeleteMySensorRegistrationToken {
  sensors {
    deleteMyRegistrationToken
  }
}
    `;
export type DeleteMySensorRegistrationTokenMutationFn = Apollo.MutationFunction<DeleteMySensorRegistrationTokenMutation, DeleteMySensorRegistrationTokenMutationVariables>;

/**
 * __useDeleteMySensorRegistrationTokenMutation__
 *
 * To run a mutation, you first call `useDeleteMySensorRegistrationTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteMySensorRegistrationTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteMySensorRegistrationTokenMutation, { data, loading, error }] = useDeleteMySensorRegistrationTokenMutation({
 *   variables: {
 *   },
 * });
 */
export function useDeleteMySensorRegistrationTokenMutation(baseOptions?: Apollo.MutationHookOptions<DeleteMySensorRegistrationTokenMutation, DeleteMySensorRegistrationTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteMySensorRegistrationTokenMutation, DeleteMySensorRegistrationTokenMutationVariables>(DeleteMySensorRegistrationTokenDocument, options);
      }
export type DeleteMySensorRegistrationTokenMutationHookResult = ReturnType<typeof useDeleteMySensorRegistrationTokenMutation>;
export type DeleteMySensorRegistrationTokenMutationResult = Apollo.MutationResult<DeleteMySensorRegistrationTokenMutation>;
export type DeleteMySensorRegistrationTokenMutationOptions = Apollo.BaseMutationOptions<DeleteMySensorRegistrationTokenMutation, DeleteMySensorRegistrationTokenMutationVariables>;
export const RegisterSensoterraSensorDocument = gql`
    mutation RegisterSensoterraSensor($command: RegisterSensoterraSensorCommand!) {
  sensors {
    registerSensoterraSensor(command: $command) {
      id
    }
  }
}
    `;
export type RegisterSensoterraSensorMutationFn = Apollo.MutationFunction<RegisterSensoterraSensorMutation, RegisterSensoterraSensorMutationVariables>;

/**
 * __useRegisterSensoterraSensorMutation__
 *
 * To run a mutation, you first call `useRegisterSensoterraSensorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterSensoterraSensorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerSensoterraSensorMutation, { data, loading, error }] = useRegisterSensoterraSensorMutation({
 *   variables: {
 *      command: // value for 'command'
 *   },
 * });
 */
export function useRegisterSensoterraSensorMutation(baseOptions?: Apollo.MutationHookOptions<RegisterSensoterraSensorMutation, RegisterSensoterraSensorMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RegisterSensoterraSensorMutation, RegisterSensoterraSensorMutationVariables>(RegisterSensoterraSensorDocument, options);
      }
export type RegisterSensoterraSensorMutationHookResult = ReturnType<typeof useRegisterSensoterraSensorMutation>;
export type RegisterSensoterraSensorMutationResult = Apollo.MutationResult<RegisterSensoterraSensorMutation>;
export type RegisterSensoterraSensorMutationOptions = Apollo.BaseMutationOptions<RegisterSensoterraSensorMutation, RegisterSensoterraSensorMutationVariables>;
export const SearchUsersDocument = gql`
    query SearchUsers($query: UserSearchQuery!) {
  userSearch(query: $query) {
    id
    createdAt
    updatedAt
    firstname
    lastname
    email
  }
}
    `;

/**
 * __useSearchUsersQuery__
 *
 * To run a query within a React component, call `useSearchUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchUsersQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useSearchUsersQuery(baseOptions: Apollo.QueryHookOptions<SearchUsersQuery, SearchUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchUsersQuery, SearchUsersQueryVariables>(SearchUsersDocument, options);
      }
export function useSearchUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchUsersQuery, SearchUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchUsersQuery, SearchUsersQueryVariables>(SearchUsersDocument, options);
        }
export type SearchUsersQueryHookResult = ReturnType<typeof useSearchUsersQuery>;
export type SearchUsersLazyQueryHookResult = ReturnType<typeof useSearchUsersLazyQuery>;
export type SearchUsersQueryResult = Apollo.QueryResult<SearchUsersQuery, SearchUsersQueryVariables>;
export const GetUserDetailsDocument = gql`
    query GetUserDetails($id: ID!) {
  user(id: $id) {
    id
    createdAt
    updatedAt
    firstname
    lastname
    email
    userRoles
    organizations {
      organization {
        orgId
        name
      }
      organizationRoles
    }
    isEmailVerified
  }
}
    `;

/**
 * __useGetUserDetailsQuery__
 *
 * To run a query within a React component, call `useGetUserDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserDetailsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetUserDetailsQuery(baseOptions: Apollo.QueryHookOptions<GetUserDetailsQuery, GetUserDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserDetailsQuery, GetUserDetailsQueryVariables>(GetUserDetailsDocument, options);
      }
export function useGetUserDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserDetailsQuery, GetUserDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserDetailsQuery, GetUserDetailsQueryVariables>(GetUserDetailsDocument, options);
        }
export type GetUserDetailsQueryHookResult = ReturnType<typeof useGetUserDetailsQuery>;
export type GetUserDetailsLazyQueryHookResult = ReturnType<typeof useGetUserDetailsLazyQuery>;
export type GetUserDetailsQueryResult = Apollo.QueryResult<GetUserDetailsQuery, GetUserDetailsQueryVariables>;
export const GetMyUserRolesDocument = gql`
    query GetMyUserRoles {
  myUserRoles
}
    `;

/**
 * __useGetMyUserRolesQuery__
 *
 * To run a query within a React component, call `useGetMyUserRolesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyUserRolesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyUserRolesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMyUserRolesQuery(baseOptions?: Apollo.QueryHookOptions<GetMyUserRolesQuery, GetMyUserRolesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMyUserRolesQuery, GetMyUserRolesQueryVariables>(GetMyUserRolesDocument, options);
      }
export function useGetMyUserRolesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMyUserRolesQuery, GetMyUserRolesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMyUserRolesQuery, GetMyUserRolesQueryVariables>(GetMyUserRolesDocument, options);
        }
export type GetMyUserRolesQueryHookResult = ReturnType<typeof useGetMyUserRolesQuery>;
export type GetMyUserRolesLazyQueryHookResult = ReturnType<typeof useGetMyUserRolesLazyQuery>;
export type GetMyUserRolesQueryResult = Apollo.QueryResult<GetMyUserRolesQuery, GetMyUserRolesQueryVariables>;
export const GetMyOrganizationRolesDocument = gql`
    query GetMyOrganizationRoles {
  myOrganizationRoles {
    organization {
      orgId
    }
    organizationRoles
  }
}
    `;

/**
 * __useGetMyOrganizationRolesQuery__
 *
 * To run a query within a React component, call `useGetMyOrganizationRolesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyOrganizationRolesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyOrganizationRolesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMyOrganizationRolesQuery(baseOptions?: Apollo.QueryHookOptions<GetMyOrganizationRolesQuery, GetMyOrganizationRolesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMyOrganizationRolesQuery, GetMyOrganizationRolesQueryVariables>(GetMyOrganizationRolesDocument, options);
      }
export function useGetMyOrganizationRolesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMyOrganizationRolesQuery, GetMyOrganizationRolesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMyOrganizationRolesQuery, GetMyOrganizationRolesQueryVariables>(GetMyOrganizationRolesDocument, options);
        }
export type GetMyOrganizationRolesQueryHookResult = ReturnType<typeof useGetMyOrganizationRolesQuery>;
export type GetMyOrganizationRolesLazyQueryHookResult = ReturnType<typeof useGetMyOrganizationRolesLazyQuery>;
export type GetMyOrganizationRolesQueryResult = Apollo.QueryResult<GetMyOrganizationRolesQuery, GetMyOrganizationRolesQueryVariables>;
export const SendEmailVerificationForUserDocument = gql`
    mutation SendEmailVerificationForUser($userId: ID!) {
  users {
    sendEmailVerification(id: $userId)
  }
}
    `;
export type SendEmailVerificationForUserMutationFn = Apollo.MutationFunction<SendEmailVerificationForUserMutation, SendEmailVerificationForUserMutationVariables>;

/**
 * __useSendEmailVerificationForUserMutation__
 *
 * To run a mutation, you first call `useSendEmailVerificationForUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendEmailVerificationForUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendEmailVerificationForUserMutation, { data, loading, error }] = useSendEmailVerificationForUserMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useSendEmailVerificationForUserMutation(baseOptions?: Apollo.MutationHookOptions<SendEmailVerificationForUserMutation, SendEmailVerificationForUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendEmailVerificationForUserMutation, SendEmailVerificationForUserMutationVariables>(SendEmailVerificationForUserDocument, options);
      }
export type SendEmailVerificationForUserMutationHookResult = ReturnType<typeof useSendEmailVerificationForUserMutation>;
export type SendEmailVerificationForUserMutationResult = Apollo.MutationResult<SendEmailVerificationForUserMutation>;
export type SendEmailVerificationForUserMutationOptions = Apollo.BaseMutationOptions<SendEmailVerificationForUserMutation, SendEmailVerificationForUserMutationVariables>;
export const UpdateUserDocument = gql`
    mutation UpdateUser($command: UpdateUserCommand!) {
  users {
    updateUser(command: $command) {
      id
      firstname
      lastname
      email
      updatedAt
    }
  }
}
    `;
export type UpdateUserMutationFn = Apollo.MutationFunction<UpdateUserMutation, UpdateUserMutationVariables>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      command: // value for 'command'
 *   },
 * });
 */
export function useUpdateUserMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserMutation, UpdateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(UpdateUserDocument, options);
      }
export type UpdateUserMutationHookResult = ReturnType<typeof useUpdateUserMutation>;
export type UpdateUserMutationResult = Apollo.MutationResult<UpdateUserMutation>;
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<UpdateUserMutation, UpdateUserMutationVariables>;
export const SetUserRolesDocument = gql`
    mutation SetUserRoles($command: SetUserRolesCommand!) {
  users {
    setUserRoles(command: $command)
  }
}
    `;
export type SetUserRolesMutationFn = Apollo.MutationFunction<SetUserRolesMutation, SetUserRolesMutationVariables>;

/**
 * __useSetUserRolesMutation__
 *
 * To run a mutation, you first call `useSetUserRolesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetUserRolesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setUserRolesMutation, { data, loading, error }] = useSetUserRolesMutation({
 *   variables: {
 *      command: // value for 'command'
 *   },
 * });
 */
export function useSetUserRolesMutation(baseOptions?: Apollo.MutationHookOptions<SetUserRolesMutation, SetUserRolesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetUserRolesMutation, SetUserRolesMutationVariables>(SetUserRolesDocument, options);
      }
export type SetUserRolesMutationHookResult = ReturnType<typeof useSetUserRolesMutation>;
export type SetUserRolesMutationResult = Apollo.MutationResult<SetUserRolesMutation>;
export type SetUserRolesMutationOptions = Apollo.BaseMutationOptions<SetUserRolesMutation, SetUserRolesMutationVariables>;
export const SetOrganizationRolesDocument = gql`
    mutation SetOrganizationRoles($command: SetOrganizationRolesCommand!) {
  users {
    setOrganizationRoles(command: $command)
  }
}
    `;
export type SetOrganizationRolesMutationFn = Apollo.MutationFunction<SetOrganizationRolesMutation, SetOrganizationRolesMutationVariables>;

/**
 * __useSetOrganizationRolesMutation__
 *
 * To run a mutation, you first call `useSetOrganizationRolesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetOrganizationRolesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setOrganizationRolesMutation, { data, loading, error }] = useSetOrganizationRolesMutation({
 *   variables: {
 *      command: // value for 'command'
 *   },
 * });
 */
export function useSetOrganizationRolesMutation(baseOptions?: Apollo.MutationHookOptions<SetOrganizationRolesMutation, SetOrganizationRolesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetOrganizationRolesMutation, SetOrganizationRolesMutationVariables>(SetOrganizationRolesDocument, options);
      }
export type SetOrganizationRolesMutationHookResult = ReturnType<typeof useSetOrganizationRolesMutation>;
export type SetOrganizationRolesMutationResult = Apollo.MutationResult<SetOrganizationRolesMutation>;
export type SetOrganizationRolesMutationOptions = Apollo.BaseMutationOptions<SetOrganizationRolesMutation, SetOrganizationRolesMutationVariables>;