import React from 'react';
import logo from '../../../images/pientere_tuinen_logo_notext.png';

function PientereTuinenMovedCard() {
  return (

    <div
      className="wccard flex rounded-lg border border-gray-400 bg-white shadow-md flex-col w-80 shrink-0 grow mb-4 justify-top"
    >
      <div className="flex h-full flex-col justify-top">
        <div className="flex bg-green-50 rounded-t-lg p-5 border-b-4 border-wc-green">
          <h4 className="inline-left mt-2 text-2xl font-bold text-gray-900">
            Mijn Pientere Tuin
          </h4>
          <img src={logo} className="inline-right h-[70px]" alt="Pientere Tuinen Logo" />
        </div>

        <div className="h-full p-5 mb-5">
          <p className="font-bold text-gray-900 dark:text-gray-400">
            <span className="text-orange-400">
              Pientere Tuinen is verhuisd naar
              {' '}
              <a
                href="https://portal.goodcitysense.nl"
                className="underline"
                rel="noreferrer"
              >
                Good City Sense
              </a>
              .
              {' '}
            </span>
            Als Pientere Tuinen gebruiker heb je een mail gekregen waarin wordt uitgelegd wat dit
            betekent en wat er gewijzigd is.
          </p>
          <p className="pt-4">
            Heb je geen email gekregen? Of heb je vragen over deze wijziging?
            Stuur dan een reactie naar de Helpdesk. We zijn blij met elke reactie.
          </p>
        </div>

        <a
          href="https://portal.goodcitysense.nl"
          className="btn-card mb-5 border group flex h-min
      items-center justify-center p-0.5 m-5 text-center font-medium focus:z-10 rounded-lg"
          rel="noreferrer"
        >
          <span className="flex items-center font-bold rounded-md text-sm px-4 py-2">
            Inloggen Good City Sense
            <svg
              className="ml-2 -mr-1 h-4 w-4"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                clipRule="evenodd"
              />
            </svg>
          </span>
        </a>

        <a
          href="mailto:support@goodcitysense.nl"
          target="_blank"
          className="btn-card border group flex h-min
      items-center justify-center p-0.5 m-5 text-center font-medium focus:z-10 rounded-lg"
          rel="noreferrer"
        >
          <span className="flex items-center font-bold rounded-md text-sm px-4 py-2">
            Mail support
            <svg
              className="ml-2 -mr-1 h-4 w-4"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                clipRule="evenodd"
              />
            </svg>
          </span>
        </a>

      </div>
    </div>
  );
}

export default PientereTuinenMovedCard;
