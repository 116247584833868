/* eslint-disable react/style-prop-object */
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { faCircleQuestion } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip } from 'flowbite-react';
import { useForm } from 'react-hook-form';
import { LatLng } from 'leaflet';
import * as Sentry from '@sentry/react';
import useLatestPientereTuinenAsset from '../../hooks/useLatestPientereTuinenAsset';
import {
  UpdateAssetCommand,
  useGetPientereTuinenSchemeQuery, useUpdatePientereTuinenAssetMutation,
} from '../../../generated/gql/types';
import Loader from '../../Loader';
import Dropdown from './Dropdown';
import {
  PientereTuinenAssetProperty,
  getProperty,
  validationStringToOptionList,
  latLongFromProperty,
} from '../../../AssetHelpers';
import MapCoordinatesPicker from './MapCoordinatesPicker';

function MyGardenForm() {
  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm<UpdateAssetCommand>();
  const { data, loading: schemaLoading } = useGetPientereTuinenSchemeQuery({ fetchPolicy: 'network-only', context: { clientName: 'asset' } });
  const [updateAsset, { loading: loadingUpdate, error: errorUpdate }] = useUpdatePientereTuinenAssetMutation({ context: { clientName: 'asset' } });
  const { asset, loading: assetLoading } = useLatestPientereTuinenAsset();

  const { t } = useTranslation();
  const defaultCoordinates = new LatLng(Number('52.084931'), Number('5.241257'));

  const schemeData = data?.scheme?.data;

  const onSubmit = handleSubmit((updateCommand) => {
    updateAsset({
      variables: {
        command: updateCommand,
      },
      refetchQueries: [
        'GetPientereTuinenAssetsForUser',
      ],
    });
  });

  useEffect(() => {
    if (errorUpdate !== null && errorUpdate !== undefined) {
      Sentry.captureMessage(errorUpdate.message, 'error');
    }
  }, [errorUpdate]);

  function renderForm() {
    if (assetLoading || !asset || schemaLoading) {
      return <Loader />;
    }

    const assetCoordinates = latLongFromProperty(
      getProperty(asset, PientereTuinenAssetProperty.Coordinates),
    );

    return (
      <>
        <input type="hidden" {...register('id', { value: asset.id })} />

        <div className="mt-2 font-bold text-gray-900 flex flex-row gap-x-1">
          {t('garden size')}
          <Tooltip
            content="Geef aan hoe groot de tuin ongeveer is."
            style="light"
          >
            <FontAwesomeIcon icon={faCircleQuestion} />
          </Tooltip>
        </div>
        <input type="hidden" {...register('properties.0.key', { value: PientereTuinenAssetProperty.GardenSize })} />
        <input type="hidden" {...register('properties.0.type', { value: PientereTuinenAssetProperty.GardenSize })} />
        <Dropdown
          hasError={errors?.properties?.[0] !== undefined}
          register={register('properties.0.value', { required: true, pattern: /^((?!undefined).)*$/, value: getProperty(asset, PientereTuinenAssetProperty.GardenSize)?.value ?? 'undefined' })}
          options={validationStringToOptionList(schemeData?.propertyTypes?.find((p) => p.name === PientereTuinenAssetProperty.GardenSize)?.validation ?? '', t)}
        />

        <div className="mt-2 font-bold text-gray-900 flex flex-row gap-x-1">
          {t('garden orientation')}
          <Tooltip
            content="Geef aan wat de ligging van de tuin is. Kies de waarde die het beste met de werkelijkheid overeenkomt."
            style="light"
          >
            <FontAwesomeIcon icon={faCircleQuestion} />
          </Tooltip>
        </div>
        <input type="hidden" {...register('properties.1.key', { value: PientereTuinenAssetProperty.GardenOrientation })} />
        <input type="hidden" {...register('properties.1.type', { value: PientereTuinenAssetProperty.GardenOrientation })} />
        <Dropdown
          hasError={errors?.properties?.[1] !== undefined}
          register={register('properties.1.value', { required: true, pattern: /^((?!undefined).)*$/, value: getProperty(asset, PientereTuinenAssetProperty.GardenOrientation)?.value ?? 'undefined' })}
          options={validationStringToOptionList(schemeData?.propertyTypes?.find((p) => p.name === PientereTuinenAssetProperty.GardenOrientation)?.validation ?? '', t)}
        />

        <div className="mt-2 font-bold text-gray-900 flex flex-row gap-x-1">
          {t('garden hardening')}
          <Tooltip
            content="Welk deel van de tuin is groen, en welk deel is verhard (niet groen)?"
            style="light"
          >
            <FontAwesomeIcon icon={faCircleQuestion} />
          </Tooltip>
        </div>
        <input type="hidden" {...register('properties.2.key', { value: PientereTuinenAssetProperty.GardenHardening })} />
        <input type="hidden" {...register('properties.2.type', { value: PientereTuinenAssetProperty.GardenHardening })} />
        <Dropdown
          hasError={errors?.properties?.[2] !== undefined}
          register={register('properties.2.value', { required: true, pattern: /^((?!undefined).)*$/, value: getProperty(asset, PientereTuinenAssetProperty.GardenHardening)?.value ?? 'undefined' })}
          options={validationStringToOptionList(schemeData?.propertyTypes?.find((p) => p.name === PientereTuinenAssetProperty.GardenHardening)?.validation ?? '', t)}
        />

        <div className="mt-2 font-bold text-gray-900 flex flex-row gap-x-1">
          {t('soil type')}
          <Tooltip
            content="Verbeter de interpretatie van je meetgegevens en stel de juiste grondsoort in."
            style="light"
          >
            <FontAwesomeIcon icon={faCircleQuestion} />
          </Tooltip>
        </div>
        <input type="hidden" {...register('properties.3.key', { value: PientereTuinenAssetProperty.SoilType })} />
        <input type="hidden" {...register('properties.3.type', { value: 'soil_type' })} />
        <Dropdown
          hasError={errors?.properties?.[3] !== undefined}
          register={register('properties.3.value', { required: true, pattern: /^((?!undefined).)*$/, value: getProperty(asset, PientereTuinenAssetProperty.SoilType)?.value ?? 'undefined' })}
          options={validationStringToOptionList(schemeData?.propertyTypes?.find((p) => p.name === 'soil_type')?.validation ?? '', t)}
        />
        <div className="mt-2 font-bold text-gray-900 flex flex-row gap-x-1">
          {t('sensor coordinates')}
          <Tooltip
            content="Geef op de kaart aan op welke plek in de tuin de sensor staat."
            style="light"
          >
            <FontAwesomeIcon icon={faCircleQuestion} />
          </Tooltip>
        </div>

        <input type="hidden" {...register('properties.4.key', { value: PientereTuinenAssetProperty.Coordinates })} />
        <input type="hidden" {...register('properties.4.type', { value: 'coordinates' })} />
        <MapCoordinatesPicker
          register={register('properties.4.value', { required: true, pattern: /[^0]+/, value: (assetCoordinates ? `${assetCoordinates.lat}, ${assetCoordinates.lng}` : `${defaultCoordinates.lat}, ${defaultCoordinates.lng}`) })}
          setValue={setValue}
          location={assetCoordinates ?? defaultCoordinates}
          zoomLevel={assetCoordinates ? 18 : 9}
          withInputFields={false}
        />

        {loadingUpdate ? (
          <button type="submit" disabled className="btn-card w-64 mt-4 mx-auto border group flex h-min items-center justify-center p-0.5 mb-5 text-center font-medium focus:z-10 rounded-lg">
            <span className="flex items-center rounded-md text-sm px-4 py-2">
              {t('save')}
            </span>
          </button>
        ) : (
          <button type="submit" className="w-64 mt-4 mx-auto border bg-wc-lightgray border-wc-gray hover:text-white hover:bg-wc-gray group flex h-min items-center justify-center p-0.5 mb-5 text-center font-medium focus:z-10 rounded-lg">
            <span className="flex items-center rounded-md text-sm px-4 py-2">
              {t('save')}
            </span>
          </button>
        )}
      </>
    );
  }

  return (
    <div className="rounded-lg border border-gray-400 bg-white shadow-md mb-8">
      <div className="p-5">
        <div>
          <h3 className="mt-2 mb-4 text-2xl font-bold text-gray-900">Mijn tuin</h3>
        </div>

        {loadingUpdate ? (
          <form className="opacity-50" onSubmit={onSubmit}>
            {renderForm()}
          </form>
        ) : (
          <form onSubmit={onSubmit}>
            {renderForm()}
          </form>
        )}
      </div>
    </div>
  );
}

export default MyGardenForm;
