import { useEffect, useState } from 'react';
import { format, formatRFC3339 } from 'date-fns';
import { nl } from 'date-fns/locale';
import * as Sentry from '@sentry/react';
import {
  HumidityMeasurement,
  TemperatureMeasurement,
  useGetDailySensorDataCubeQueryQuery,
} from '../../generated/gql/types';

/* eslint no-underscore-dangle: 0 */
function useDailyPientereTuinenSensorData({ from, to }:
DailySensorGraphDataProps): SensorGraphData {
  const [sensorGraphData, setSensorGraphData] = useState<SensorGraphData>({
    temp: [],
    humid: [],
  });

  const { data: apiData, loading: dataLoading, error: dataError } = useGetDailySensorDataCubeQueryQuery({
    fetchPolicy: 'network-only',
    context: { clientName: 'cube' },
    variables: {
      where: {
        measured_at: {
          inDateRange: [formatRFC3339(from), formatRFC3339(to)],
        },
      },
    },
  });

  function formatForGraph(measurement: HumidityMeasurement | TemperatureMeasurement): IRecord {
    const dt = new Date(measurement.dateTime);
    return {
      timestamp: dt.toString(),
      height: 0,
      day: format(dt, 'd MMM y', { locale: nl }),
      hour: format(dt, 'H:mm', { locale: nl }),
      value: measurement.value?.toFixed(1) ?? 0,
      unit: measurement.__typename === 'TemperatureMeasurement' ? '°C' : '%',
    } as IRecord;
  }

  useEffect(() => {
    if (apiData?.cube) {
      const humidityMeasurements: HumidityMeasurement[] = apiData?.cube.map((measurement) => (
        {
          dateTime: measurement.pientere_tuinen_measurements.measured_at?.day,
          value: (measurement.pientere_tuinen_measurements.avg_moisture_percentage ?? 0) * 100,
        } as HumidityMeasurement
      ));

      const temperatureMeasurements: TemperatureMeasurement[] = apiData?.cube.map((measurement) => (
        {
          dateTime: measurement.pientere_tuinen_measurements.measured_at?.day,
          value: measurement.pientere_tuinen_measurements.avg_temperature_celsius,
        } as TemperatureMeasurement
      ));

      setSensorGraphData({
        humid: humidityMeasurements.filter((m) => m.value != null).map(formatForGraph),
        temp: temperatureMeasurements.filter((m) => m.value != null).map(formatForGraph),
      });
    }
  }, [dataLoading, apiData]);

  useEffect(() => {
    if (dataError !== null && dataError !== undefined) {
      Sentry.captureMessage(dataError.message, 'error');
    }
  }, [dataError]);

  return sensorGraphData;
}

type IRecord = {
  timestamp: string,
  height: number,
  unit: string,
  value: number,
  day: string,
  hour: string
};

export type SensorGraphData = {
  temp: IRecord[],
  humid: IRecord[],
};

export type DailySensorGraphDataProps = {
  from: Date
  to: Date
};

export default useDailyPientereTuinenSensorData;
