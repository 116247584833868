import React, { useState } from 'react';
import {
  CartesianGrid, ComposedChart, Line, ReferenceArea, ResponsiveContainer, Tooltip, XAxis, YAxis,
} from 'recharts';
import { setMinutes, setSeconds, subDays } from 'date-fns';
import usePientereTuinenSoilTypes, { defaultSoilType, SoilType } from '../../hooks/usePientereTuinenSoilTypes';
import useHourlyPientereTuinenSensorData from '../../hooks/useHourlyPientereTuinenSensorData';
import useDailyPientereTuinenSensorData from '../../hooks/useDailyPientereTuinenSensorData';

function CustomTooltipTemp({ active, payload }: any) {
  if (active && payload && payload.length) {
    return (
      <div className="custom-tooltip">
        <p className="label">
          {payload[0].payload.day}
          &nbsp;
          <b>{payload[0].payload.hour}</b>
        </p>
        <p className="label">
          Temperatuur:
          &nbsp;
          {payload[0].value}
          &deg;C
        </p>
      </div>
    );
  }

  return null;
}

function CustomTooltipHumid({ active, payload }: any) {
  if (active && payload && payload.length) {
    return (
      <div className="custom-tooltip">
        <p className="label">
          {payload[0].payload.day}
          &nbsp;
          <b>
            {payload[0].payload.hour}
          </b>
        </p>
        <p className="label">
          Vochtigheid:
          &nbsp;
          {payload[0].value}
          %
        </p>
      </div>
    );
  }

  return null;
}

// define x-axis ticks date and possibly hour in 2 lines
function CustomizedTick(props: any) {
  const { x, y, payload } = props;
  const valarr = payload.value.split('|');
  return (
    <g transform={`translate(${x},${y})`}>
      <text x={0} y={0} dy={16} fill="#666">
        <tspan textAnchor="middle" x="0">
          {valarr[0]}
        </tspan>
        <tspan textAnchor="middle" x="0" dy="20">
          {valarr.length > 1 ? valarr[1] : ''}
        </tspan>
      </text>
    </g>
  );
}

function Charts({ startDateOfMeasurements, soilType }: ChartsProps) {
  const soilTypes = usePientereTuinenSoilTypes();
  // current radio button settings
  const [graphTemperatureRadio, setGraphTemperatureRadio] = useState('hour');
  const [graphHumidityRadio, setGraphHumidityRadio] = useState('hour');

  // current graph
  const [chartTemperature, setChartTemperature] = useState('TempHour');
  const [chartHumidity, setChartHumidity] = useState('HumidHour');

  // Round dates to help caching the queries
  const fromDate = setSeconds(setMinutes(subDays(new Date(), 7), 0), 0);
  const toDate = setSeconds(setMinutes(new Date(), 0), 0);

  const sensorDataHour = useHourlyPientereTuinenSensorData({ from: fromDate, to: toDate });
  const sensorDataDay = useDailyPientereTuinenSensorData({ from: startDateOfMeasurements, to: toDate });

  // return y coords for the green soil area
  const getGreenSoilArea = () => {
    if (soilTypes.length > 0) {
      const soil = soilTypes.find((element) => element.id === (soilType?.id ?? defaultSoilType.id));
      return [soil?.default_norm_min, soil?.default_norm_max];
    }
    return [0, 0];
  };

  // return y coords for the orange soil area (wilting area)
  const getOrangeSoilArea = () => {
    if (soilTypes.length > 0) {
      const soil = soilTypes.find((element) => element.id === (soilType?.id ?? defaultSoilType.id));
      return [soil?.wilting_point, soil?.default_norm_min];
    }
    return [0, 0];
  };

  const formatDate = (date: any) => {
    if (date.hour !== null) {
      return `${date.day}|${date.hour}`;
    }
    return date.day;
  };

  return (
    <>
      <div className="rounded-lg border border-gray-400 bg-white shadow-md mb-8">
        <div className="graph p-5">
          <div>
            <h3 className="mt-2 mb-4 text-2xl font-bold text-gray-900">Bodemtemperatuur (&deg;C)</h3>
          </div>

          <div className="rounded-lg border border-gray-200 bg-white mb-6 h-[300px]">

            {(chartTemperature === 'TempHour'
              ? (
                <ResponsiveContainer>
                  <ComposedChart
                    width={500}
                    height={400}
                    data={sensorDataHour.temp}
                    margin={{
                      top: 20, right: 5, bottom: 20, left: 5,
                    }}
                  >
                    <CartesianGrid stroke="#f5f5f5" />
                    <XAxis dataKey={formatDate} tick={<CustomizedTick />} minTickGap={20} />
                    <YAxis domain={[-20, 60]} />
                    <Tooltip content={<CustomTooltipTemp />} />
                    <Line type="monotone" dataKey="value" stroke="#000" dot={false} connectNulls />
                    <Line type="monotone" dataKey="TempExt" stroke="#c00" dot={false} connectNulls />
                  </ComposedChart>
                </ResponsiveContainer>
              ) : ''
            )}

            {(chartTemperature === 'TempDay'
              ? (
                <ResponsiveContainer>
                  <ComposedChart
                    width={500}
                    height={400}
                    data={sensorDataDay.temp}
                    margin={{
                      top: 20, right: 5, bottom: 20, left: 5,
                    }}
                  >
                    <CartesianGrid stroke="#f5f5f5" />
                    <XAxis dataKey={formatDate} tick={<CustomizedTick />} minTickGap={20} />
                    <YAxis domain={[-20, 60]} />
                    <Tooltip content={<CustomTooltipTemp />} />
                    <Line type="monotone" dataKey="value" stroke="#000" dot={false} connectNulls />
                    <Line type="monotone" dataKey="TempExt" stroke="#c00" dot={false} connectNulls />
                  </ComposedChart>
                </ResponsiveContainer>
              ) : '')}
          </div>
          <form>
            <div className="flex justify-center">
              <div className="flex items-center mr-4">
                <input
                  id="inline-radio-t1"
                  type="radio"
                  value="hour"
                  name="graph-temp-radio"
                  className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 focus:ring-2"
                  onChange={(e) => {
                    setGraphTemperatureRadio(e.target.value);
                    setChartTemperature('TempHour');
                  }}
                  checked={graphTemperatureRadio === 'hour'}
                />
                {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                <label
                  htmlFor="inline-radio-t1"
                  className="ml-2 text-sm font-medium text-gray-900"
                >
                  Laatste 7 dagen, per uur
                </label>
              </div>
              <div className="flex items-center mr-4">
                <input
                  id="inline-radio-t2"
                  type="radio"
                  value="day"
                  name="graph-temp-radio"
                  className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 focus:ring-2"
                  onChange={(e) => {
                    setGraphTemperatureRadio(e.target.value);
                    setChartTemperature('TempDay');
                  }}
                  checked={graphTemperatureRadio === 'day'}
                />
                {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                <label
                  htmlFor="inline-radio-t2"
                  className="ml-2 text-sm font-medium text-gray-900"
                >
                  Per dag
                </label>
              </div>
            </div>
          </form>
        </div>
      </div>

      <div className="rounded-lg border border-gray-400 bg-white shadow-md mb-8">
        <div className="graph p-5">
          <div>
            <h3 className="mt-2 text-2xl font-bold text-gray-900">Bodemvocht (%)</h3>
            <p className="text-sm mb-4">
              De vochtigheid van jouw grondsoort
              {' '}
              {soilType?.name ?? defaultSoilType.name}
              {' '}
              is optimaal als de waarde in het groene vlak ligt. Ligt de waarde meerdere dagen in het oranje vlak, geef de planten dan water.
            </p>
          </div>
          <div className="rounded-lg border border-gray-200 bg-white mb-6 h-[300px]">

            {(chartHumidity === 'HumidHour'
              ? (
                <ResponsiveContainer>
                  <ComposedChart
                    width={500}
                    height={400}
                    data={sensorDataHour.humid}
                    margin={{
                      top: 20, right: 5, bottom: 20, left: 5,
                    }}
                  >
                    <CartesianGrid stroke="#f5f5f5" />
                    <XAxis dataKey={formatDate} tick={<CustomizedTick />} minTickGap={20} />
                    <YAxis domain={[0, 100]} />
                    <ReferenceArea fill="#def7ec" fillOpacity="0.9" y1={getGreenSoilArea()[0]} y2={getGreenSoilArea()[1]} />
                    <ReferenceArea fill="#fce2a9" fillOpacity="0.9" y1={getOrangeSoilArea()[0]} y2={getOrangeSoilArea()[1]} />
                    <Tooltip content={<CustomTooltipHumid />} />
                    <Line type="monotone" dataKey="value" stroke="#000" dot={false} connectNulls />
                  </ComposedChart>
                </ResponsiveContainer>
              ) : '')}

            {(chartHumidity === 'HumidDay'
              ? (
                <ResponsiveContainer>
                  <ComposedChart
                    width={500}
                    height={400}
                    data={sensorDataDay.humid}
                    margin={{
                      top: 20, right: 5, bottom: 20, left: 5,
                    }}
                  >
                    <CartesianGrid stroke="#f5f5f5" />
                    <XAxis dataKey={formatDate} tick={<CustomizedTick />} minTickGap={20} />
                    <YAxis domain={[0, 100]} />
                    <ReferenceArea fill="#def7ec" fillOpacity="0.9" y1={getGreenSoilArea()[0]} y2={getGreenSoilArea()[1]} />
                    <ReferenceArea fill="#fce2a9" fillOpacity="0.9" y1={getOrangeSoilArea()[0]} y2={getOrangeSoilArea()[1]} />
                    <Tooltip content={<CustomTooltipHumid />} />
                    <Line type="monotone" dataKey="value" stroke="#000" dot={false} connectNulls />
                  </ComposedChart>
                </ResponsiveContainer>
              ) : '')}
          </div>

          <form>
            <div className="flex justify-center">
              <div className="flex items-center mr-4">
                <input
                  id="inline-radio-h1"
                  type="radio"
                  value="hour"
                  name="graph-hum-radio"
                  className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 focus:ring-2"
                  onChange={(e) => {
                    setGraphHumidityRadio(e.target.value);
                    setChartHumidity('HumidHour');
                  }}
                  checked={graphHumidityRadio === 'hour'}
                />
                {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                <label htmlFor="inline-radio-h1" className="ml-2 text-sm font-medium text-gray-900">
                  Laatste 7 dagen, per uur
                </label>
              </div>
              <div className="flex items-center mr-4">
                <input
                  id="inline-radio-h2"
                  type="radio"
                  value="day"
                  name="graph-hum-radio"
                  className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 focus:ring-2"
                  onChange={(e) => {
                    setGraphHumidityRadio(e.target.value);
                    setChartHumidity('HumidDay');
                  }}
                  checked={graphHumidityRadio === 'day'}
                />
                {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                <label htmlFor="inline-radio-h2" className="ml-2 text-sm font-medium text-gray-900">
                  Per dag
                </label>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

type ChartsProps = {
  soilType: SoilType
  startDateOfMeasurements: Date
};

export default Charts;
